import SideNav from "./SideNav";
import TopBar from "./TopBar";
import { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import axios from 'axios';
import {Link, useParams, useNavigate} from "react-router-dom";
import ReactPaginate from 'react-paginate';

function SettingInvoicePreset(){

      const API_URL = process.env.REACT_APP_API_URL;
      const [item_name, setItem_name] = useState("");
      const [quantity, setQuantity] = useState(1);
      const [price, setPrice] = useState("");
      const [isLoading, setIsLoading] = useState(true);
      const [loading, setLoading] = useState(false);

      const [error_list, setErrorList] = useState([]);
      const [invoice_preset, setInvoice_preset] = useState([]);
      const [message, setMessage] = useState('');
      const [searchQuery, setSearchQuery] = useState("");
      const [currentPage, setCurrentPage] = useState(0);
      const [perPage, setPerPage] = useState(10);

      const filteredResult = invoice_preset.filter((item) => {
        return item.item_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.price.toString().includes(searchQuery) 
      });

   
      const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
      };

      useEffect(() => {
        setIsLoading(true);
        getInvoicePresetList();
       
    }, []);


       function getInvoicePresetList()
      {
        
        axios.get(`${API_URL}/view-invoice-preset`).then(res=>{
            if(res.data.status === 200){
                setInvoice_preset(res.data.invoicePreset); 
                
            }     
            else if(res.data.status === 404){
                setInvoice_preset([]);
                }
                setIsLoading(false);
         });  
       
      }

      async function save(event)
      {
        
          event.preventDefault();
          setLoading(true);
          const formData = new FormData()
    
          formData.append('item_name', item_name)
          formData.append('quantity', quantity)
          formData.append('price', price) 
      
          await axios.post(`${API_URL}/save-invoice-preset`, formData).then(res =>{
         
            Swal.fire({
              icon:"success",
              text:res.data.message
            }).then(function(){ 
                setLoading(false);        
                 resetData();
                 setErrorList([]);
                 getInvoicePresetList();
                 
          });
          }).catch(function(error) {
            setLoading(false);
            // if(error.response.status===422){         
            setErrorList(error.response.data.validate_err);
            // }
            // else{
            //   Swal.fire({
            //     text:error.response.data.message,
            //     icon:"error"
            //   })
            // }
          })
     }
    
    
     const resetData = () => {
        setItem_name("");
        setPrice("");
        };


        const deleteInvoicePreset = (e,id) =>{
            e.preventDefault();
            var buttonClicked = window.confirm("Are you sure you want to delete invoice preset item?");
            if(buttonClicked == true){
               // const thisClicked = e.currentTarget;
               // thisClicked.innerText = "Deleting";
                axios.delete(`${API_URL}/delete-invoice-preset/`+id).then(res => {
                 
              Swal.fire({
                      icon:"success",
                      text:res.data.message
                    }).then(function(){
                       // thisClicked.closest("tr").remove();  
                        
                  });

                   getInvoicePresetList();
                })
            }
            else
            {
                getInvoicePresetList();
            }
           
           }


 
           
         
           
                   
         
          

    return(
        <div>
            <SideNav/>
            <TopBar/>

            {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}


   <div class="right_panel position contact_section vh-100" id="right_panel">

<div class="main_title d-flex align-items-center">
    <h1><i class="bi bi-sticky"></i> Create Invoice Preset</h1>
</div>

<div class="content_wrapper pt-0">
    <div class="vm_content_box_1">
        <div class="title">
            <h2>Create Invoice Preset</h2>
        </div>
        <div class="content pt-3">  
                    
                        <div class="content pt-4 pb-4">
                            <div class="row">
                            <div class="col-md-4 form-group">
                                    <input type="text" class="form-control" name="item_name" id="item_name" value={item_name} placeholder="Item Name"
                                     onChange={(event) =>{ setItem_name(event.target.value); }}/>
                                       <span class="text-danger">{error_list.item_name}</span>
                                </div>
                                
                                <div class="col-md-2 form-group">
                                    <input type="text" class="form-control" name="price" id="price" value={price} placeholder="Price"
                                      onChange={(event) =>{ setPrice(event.target.value); }}/>
                                   <span class="text-danger" style={{ whiteSpace: 'nowrap', display: 'block' }}>{error_list.price}</span>
                                </div>
                                {/* <div class="col-md-2 form-group">
                                    < input type="text" class="form-control" name="quantity" id="quantity" value={quantity} placeholder="Quantity"
                                   readOnly onChange={(event) =>{ setQuantity(event.target.value); }}/>
                                    <span class="text-danger">{error_list.quantity}</span>
                                </div> */}
                            </div>
                           
                           
                         {/* <button class="border px-2 py-2 d-inline vm_bg_secondary text-white vm_cursor_pointer" onClick={save}>
                         <i class="bi bi-save me-2"></i> {loading?"Saving":"Save"}
                    </button> */}
                    <button onClick={save} class="btn btn-sm btn_secondary text-white"><i class="bi bi-save me-2"></i> {loading?"Saving":"Save"}</button>
                            
                            </div>
            
        </div>
    </div>

    <div class="vm_content_box_1 mt-4">
        <div class="title">
            <h2>Manage Invoice Preset</h2>
        </div>

        <div class="content">
        <div class="row pt-3">
                          
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                              
                   
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search Invoice Items" type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                          </div>

                         
                            <><table class="display dataTable dtr-inline" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Price</th>
                                            <th class="text-end">Action</th>
                                        </tr>
                                    </thead>


                                    <tbody>
                                    {filteredResult.length == 0 ?
        <table class="display dataTable dtr-inline">
        <tbody>
          <tr>
            <div class="row" style={{margin: "10px -900px 10px 10px"}}>
                       <p  valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                       </div>
          </tr>
        </tbody>
      </table>
      :    null }

                                        {filteredResult.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
                                            <tr key={index}>

                                                <td>
                                                    <p>{item.item_name}</p>
                                                </td>

                                                <td>
                                                    <p>{'$'}{item.price}</p>
                                                </td>


                                                <td class="d-flex justify-content-end">

                                                    <Link to={`/edit-invoice-preset/${item.id}`} class="btn btn-sm btn_secondary" data-bs-target="#createNewCustomre">
                                                        <i class="bi bi-pencil-square"></i> Edit</Link>

                                                    <button class="btn btn-sm btn_secondary" style={{ background: "red", color: "white", margin: "0px 0px 0px 10px" }}
                                                        onClick={(e) => deleteInvoicePreset(e, item.id)}><i class="bi bi-trash3"></i> Delete</button>
                                                </td>
                                            </tr>


                                        ))}
                                    </tbody>

                                    <tfoot>
                                        <tr>
                                            <th>Name</th>
                                            <th>Price</th>
                                            <th class="text-end">Action</th>
                                        </tr>
                                    </tfoot>
                                </table><div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                        {filteredResult.length > 0 && `Showing 1 to ${Math.min(perPage, filteredResult.length)} of ${filteredResult.length} entries`}
                                    </div><div class="col-md-9" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredResult.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div></>
                      

        </div>
    </div>
</div>

</div>



        </div>
    )
}

export default SettingInvoicePreset;