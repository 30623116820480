import SideNav from "./SideNav";
import TopBar from "./TopBar";
import React, { useState,  useEffect, useRef } from 'react';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Flickity from 'react-flickity-component';
import 'flickity/css/flickity.css';
import 'flickity-as-nav-for';
import { useLocation } from "react-router-dom";
import $ from 'jquery';
import { Modal, Carousel, Button } from 'react-bootstrap';
import placeholderImage from '../img/image_placeholder.png';
import Swal from "sweetalert2";


function VehicleList(){

    const [inventory, setInventory] = useState([]);
    const [coverImages, setCoverImages] = useState([]);
    const [mergedArray, setMergedArray] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const [successMessage, setSuccessMessage] = useState('');
    const [showComponents, setShowComponents] = useState(true);
    const mainCarouselRef = useRef(null);
    const navCarouselRef = useRef(null);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
   
    const searchParams = new URLSearchParams(location.search);
    const selectedRows = searchParams.get("selectedRows");
    const selectedMakes = searchParams.get('selectedMakes');
const selectedModels = searchParams.get('selectedModels');
const customerEmail = searchParams.get("email");
const customerPhoneNo = searchParams.get("phoneNo");

const vehicleListName = (selectedMakes && selectedModels) ? `${selectedMakes} ${selectedModels}` : (selectedMakes || selectedModels);

    const API_URL = process.env.REACT_APP_API_URL;
    const LARAVEL_API_URL = process.env.REACT_APP_LARAVEL_API;

    const logoImagePath = '/img/logo_main_black.webp';
    const [email, setEmail] = useState('');
    const [phone_no, setPhone_no] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
   const [selectedImageIndexes, setSelectedImageIndexes] = useState(Array(mergedArray.length).fill(null));
   const [activeTab, setActiveTab] = useState(''); 
   const [sms_template, setSms_template] = useState([]);
   const [text_message, setText_message] = useState("");
   const [text_template, setText_template] = useState("");
   const [text_template_details, setText_template_details] = useState([]);  
   const [error_list, setErrorList] = useState([]);
   const [email_error_list, setEmailErrorList] = useState([]);
   const [images, setImages] = useState([]);
   const [previews, setPreviews] = useState([]);
   const [email_template, setEmail_template] = useState([]);
   const [template, setTemplate] = useState("");
    const [template_details, setTemplate_details] = useState([]);
    const [email_toggle_selected, setEmail_toggle_selected] = useState(false);
  const [sms_toggle_selected, setSms_toggle_selected] = useState(false);

   const showToast = (message) => {
    Swal.fire({
      icon: 'success',
      title: message,
      color:"white",
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3500,
      customClass: {
          popup: 'sweetalert-popup',
          title: 'sweetalert-title',
          icon: 'sweetalert-icon',
        },
        background: '#333',
        showCloseButton: true,
        padding: '4px', 
    });
  };
  
  const showToastFail = (message) => {
      Swal.fire({
        icon: 'error',
        title: message,
        color:"white",
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3500,
        customClass: {
            popup: 'sweetalert-popup',
            title: 'sweetalert-title',
            icon: 'sweetalert-icon',
          },
          background: '#333',
          showCloseButton: true,
          padding: '4px', 
      });
    };

    const getFileType = (url) => {
      const extension = url.split('.').pop().toLowerCase();
      switch (extension) {
          case 'jpg':
          case 'jpeg':
          case 'png':
              case 'webp':
              return 'image';
          case 'pdf':
              return 'pdf';
          case 'mp4':
          case 'avi':
          case 'mov':
              return 'video';
          // Add more file types as needed
          default:
              return 'unknown';
      }
  };

  const resetEmail = () => {
    setTemplate([]);
   if (customerEmail == null){setEmail('');}
    setSubject("");
    setMessage("");
    setImages([]);
    setPreviews([]);
     };

    const resetSms = () => {
      setText_template([]);
      setText_message("");
       };
  
    useEffect(() => {   
      setIsLoading(true);
      getEmailTemplateList();
      getSmsTemplateList();
        // console.log(selectedImageIndexes);

        AOS.init({ duration: 1200 });
     
        getInventoryList(); 
        //const currentPageUrl = window.location.origin + window.location.pathname;
      const currentPageUrl = window.location.href;
      const defaultSubject = `Check out ${vehicleListName ? vehicleListName + ' ' : ''}listings for sale in Canada`;
      const defaultMessages = `<h2>Thank You for contact us! </h2>I found this vehicle list and thought you might be interested: <a href="${currentPageUrl}?email=true"  target="_blank">View Vehicle List </a>`;
      const defaultMessage = `<div class="email-message">
     
      <div class="header">
        <img src="https://www.attodesk.com/Auttodesk-Black.png" alt="Attodesk Logo Big" width="140" />
      </div>
      
      <div class="body">
      <p>Thank you for your interest in <b>${vehicleListName ? vehicleListName + ' ' : ''}</b> vehicle listings. It’s absolutely one of the best cars on our lot. </p>
        <p>Check out ${vehicleListName ? vehicleListName + ' ' : ''} vehicle list: <a href="${currentPageUrl}?email=true"  target="_blank" style="color: cornflowerblue; text-decoration: underline;">
        View Listings </a></p>
        <p>Looking forward to hearing from you!</p>   
      </div>
      <footer>
      <br>
    <p>Regards,<br>Attodesk team,<p>
    <p style="color:rgb(51, 102, 153)">415 The West Plaza, Ste. 110
    Toronto, Ontario A9C 5J1<br>Phone No: +1 (647) 555-5678</p>
      </footer>
    </div>`;
    //setSubject(defaultSubject);
    // setMessage(defaultMessage);

  
     if (currentPageUrl.includes("email=true")) {
       setShowComponents(false);
       hideMenu(); 
} 

      
         }, []);


         useEffect(() => {
            if (email_toggle_selected) {
              setActiveTab('email');
            } else if (sms_toggle_selected) {
              setActiveTab('sms');
            }
       
        }, [email_toggle_selected, sms_toggle_selected]);
        
        
   function  getInventoryList() {
        axios.get(`${API_URL}/view-inventory`).then(res=>{
            if(res.data.status === 200){
              const inventory = res.data.inventorySection;
              const filteredInventory = inventory.filter(section => selectedRows.includes(section.id));
                setInventory(res.data.inventorySection);             
                setCoverImages(res.data.inventoryCoverImages);

                  const mergedArray =    filteredInventory.map(section => {
                  const inventorySection2 = res.data.inventorySection2.find(data => data.inventory_section_id === section.id);
                  const coverImage = res.data.inventoryCoverImages.find(image => image.inventory_section_id === section.id);
                  const price = res.data.inventorySection3.find(data => data.inventory_section_id === section.id);
                  const inventoryImages = res.data.inventoryImages.find(image => image.inventory_section_id === section.id);
               const count = ((inventoryImages && inventoryImages.filepath_compressed ? inventoryImages.filepath_compressed.split(',').length : 0) +
               (coverImage && coverImage.filepath ? coverImage.filepath.split(',').length : 0));
                  return { ...section, inventorySection2, coverImage, price, count };    
               
                })
              
                setMergedArray(filteredInventory.map(section => {
                    const inventorySection2 = res.data.inventorySection2.find(data => data.inventory_section_id === section.id);
                    const coverImage = res.data.inventoryCoverImages.find(image => image.inventory_section_id === section.id);
                    const price = res.data.inventorySection3.find(data => data.inventory_section_id === section.id);
                    const inventoryImages = res.data.inventoryImages.find(image => image.inventory_section_id === section.id);
                    const count = ((inventoryImages && inventoryImages.filepath_compressed ? inventoryImages.filepath_compressed.split(',').length : 0) +
                    (coverImage && coverImage.filepath ? coverImage.filepath.split(',').length : 0));           
                    return { ...section, inventorySection2, coverImage, price, inventoryImages, count };    
                 
                  }))   

           
                   
                }
           else if(res.data.status === 404){
               //setMessage(res.data.message);
               //console.log(res.data.status);
               }
           setIsLoading(false);
        });
       
       }

       function getEmailTemplateList()
    {    
      axios.get(`${API_URL}/view-email-template`).then(res=>{
          if(res.data.status === 200){
              setEmail_template(res.data.emailTemplate);       
          }     
          else if(res.data.status === 404){
            setEmail_template([]);
             // setMessage(res.data.message);     
              }             
       });    
    }

       
    function getSmsTemplateList()
    {    
      axios.get(`${API_URL}/view-sms-template`).then(res=>{
          if(res.data.status === 200){
              setSms_template(res.data.smsTemplate);       
          }     
          else if(res.data.status === 404){
            setSms_template([]);
             // setMessage(res.data.message);     
              }             
       });    
    }
       
       const sendEmail = (e) => {
     
        e.preventDefault();
       
        setLoading(true);
        let emailArray;
        if (customerEmail) {
          emailArray = customerEmail.split(",");
        }
        else{
         emailArray = email.split(",");
        }


        // const formattedMessage = (template_details?.message ?? '').replace(/\n/g, '<br>');
                    
        // const messageWithLogo = `<img src="https://www.attodesk.com/Auttodesk-Black.png" alt="Attodesk Logo Big" width="140" /><br><br>${formattedMessage}`;
        
        const formData = new FormData();
        formData.append("to", emailArray);
        formData.append("subject", subject);
        formData.append("message", message.replace(/\n/g, '<br>'));
      //   if(template_details.message){
      //     formData.append("message", formattedMessage);
      // }
      // else{
      //     formData.append("message", message);
      // }
      
        images.forEach((image, index) => {
          formData.append(`image[${index}]`, image);
        });

          //console.log(images)
        // axios.post(`${API_URL}/send-email-attachment`, { to: emailArray, subject, message })
        //   .then(() => {

          axios.post(`${API_URL}/send-email-attachment`, formData)
          .then(() => {
           
           // console.log('Email sent successfully');
           // setSuccessMessage('Email sent successfully!');
           setLoading(false);
           showToast("Email sent successfully!"); 
           setEmailErrorList([]);
           setTemplate_details([]);
           resetEmail();
           
          })
          .catch((error) => {
            setLoading(false);
            setEmailErrorList(error.response.data.validate_err);
            
           console.error('Error sending email:', error.response.data.message);
          });
      };

      const sendSMS = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        if(customerPhoneNo){
          formData.append("phone_no", customerPhoneNo);
        }else
        {
          formData.append("phone_no", phone_no);
          //console.log(phone_no);
        }
       
        formData.append("message", text_message);
        const response = await  axios.post(`${API_URL}/send-sms`, formData) .then(() => {
          //   console.log('SMS sent successfully:', response.data);
            showToast("SMS sent successfully!"); 
            setText_template_details([]);
            resetSms();
             setErrorList([]);
            setLoading(false);
          
          })
          .catch((error) => {
              setLoading(false);
            //console.error('Failed to send SMS:', error);      
          //   setError(error.response.data.message || 'Failed to send SMS');
          setErrorList(error.response.data.validate_err);
      
          // Swal.fire({
          //     text:error.response.data.message,error_list
          //     icon:"error"
          //   })
      
          //   showToastFail("Failed to send SMS");
          }) 
        };


      function hideMenu() {
        document.getElementById("left_panel").style.left = "-127px";
        document.getElementById("left_panel").style.transition = "all 0.5s ease";
        document.getElementById("left_panel").classList.add("menuHoverClass");
        document.getElementById("topbar").style.paddingLeft = "57px";
        document.getElementById("right_panel").style.paddingLeft = "46px";
        document.getElementById("hideMenu").style.display = "none";
        document.getElementById("showMenu").style.display = "block";
        document.getElementById("mainlogo_big").style.display = "none";
        document.getElementById("mainlogo_small").style.display = "flex";
        $(".cd-accordion__input").prop("checked", true);
      }

      const handleTextTemplateChange  = (event) => {
        setText_template(event.target.value);
        if(event.target.value){
            const text_template_details = sms_template.find(item=> item.template_name === event.target.value);
            setText_template_details(text_template_details);
         //   setText_message(text_template_details.message);

           
            const currentPageUrl = window.location.href;
            let updatedMessage = text_template_details.message
                .replace(/\[model\]/g, vehicleListName ? vehicleListName : '')
                .replace(/\[link\]/g, currentPageUrl + '?email=true');
               
    
            setText_message(updatedMessage);
       
        }
        else
        {   
            setText_template_details([]);
            setText_message("");
        }
        };   

        const handleTemplateChange  = (event) => {
          setTemplate(event.target.value);
          if(event.target.value){
              const template_details = email_template.find(item=> item.template_name === event.target.value);
              setTemplate_details(template_details);

              const currentPageUrl = window.location.href;

           // let updatedSubject = template_details.subject.replace(/\[model\]/g, vehicleListName ? vehicleListName : '')
           let updatedSubject = template_details.subject.replace(/\[model\]/g, vehicleListName && (selectedMakes.split(',').length == 1 || selectedModels.split(',').length == 1 )? vehicleListName : '')
           let updatedMessage = template_details.message
               // .replace(/\[model\]/g, vehicleListName  ? vehicleListName : '')
                .replace(/\[model\]/g, vehicleListName && (selectedMakes.split(',').length == 1 || selectedModels.split(',').length == 1 )? vehicleListName : '')
                .replace(/\[link\]/g, currentPageUrl + '?email=true')
          
                setSubject(updatedSubject);
              setMessage(updatedMessage);


              if (template_details.image_path) {
  
                  const filepathArray = template_details.image_path.split(',');
                  const imageUrlArray = filepathArray.map(filepath => `${LARAVEL_API_URL}/${filepath}`);
                  setPreviews(imageUrlArray);
      
                  const initialPreviewsPromises = imageUrlArray.map(async filepath => {
                      const filename = filepath.substring(filepath.lastIndexOf('/') + 1); // Extract filename from URL
                      const fileType = filename.split('.').pop().toLowerCase(); // Extract file extension and convert to lowercase
                  
                      if (fileType === 'pdf') {
                          // For PDF files
                          try {
                              const response = await fetch(filepath);
                              const blob = await response.blob();
                              return new File([blob], filename, { type: 'application/pdf' });
                          } catch (error) {
                              console.error('Error fetching PDF file:', error);
                              return null;
                          }
                      } else if (['mp4', 'webm', 'ogg'].includes(fileType)) {
                          try {
                              const response = await fetch(filepath);
                              const blob = await response.blob();
                              return new File([blob], filename, { type: `video/${fileType}` });
                          } catch (error) {
                              console.error('Error fetching video:', error);
                              return null;
                          }
                      } else {
                          // For image files
                          try {
                              const response = await fetch(filepath);
                              const blob = await response.blob();
                              return new File([blob], filename, { type: `image/${fileType}` });
                          } catch (error) {
                              console.error('Error fetching image:', error);
                              return null;
                          }
                      }
                  });
                  
                  Promise.all(initialPreviewsPromises)
                      .then(initialPreviews => {
                          // Filter out null values (in case of error)
                          const filteredPreviews = initialPreviews.filter(preview => preview !== null);
                          setImages(filteredPreviews);
                      })
                      .catch(error => {
                         // console.error('Error creating initial previews:', error);
                          setImages([]); // Set empty array in case of error
                      });
                  
                  const updatedPreviews = imageUrlArray.map(url => {
                      const fileType = getFileType(url);
                      return { type: fileType, url: url };
                  });
                  setPreviews(updatedPreviews);
              } 
          
          }
          else
          {   setTemplate(""); 
              setTemplate_details([]);
              setSubject("");
              setMessage("");
              setPreviews([]);
              setImages([]);
          }
          };

          function onSelectImages(event){
            const selectedImages = Array.from(event.target.files);

                const validImages = selectedImages.filter(image => image.size <= 15 * 1024 * 1024); // 15MB 
                if (validImages.length !== selectedImages.length) {
                  Swal.fire({
                    text: "Image file size should be less than or equal to 15MB.",
                    icon: "error"
                  });
                }       
                setImages([...images, ...validImages]);       
            
               //  console.log([...images, ...validImages]);
               // const filePreviews = validImages.map((image) => URL.createObjectURL(image));
        
                const filePreviews = validImages.map(file => {
                if (file.type === 'application/pdf') {
                    return { type: 'pdf', name: file.name , url: URL.createObjectURL(file)};
                } else if (file.type === 'video/mp4') {
                  return { type: 'video', name: file.name, url: URL.createObjectURL(file) };
                 } else {
                    return { type: 'image', name: file.name ,url: URL.createObjectURL(file) };
                }
            });
        
                setPreviews([...previews, ...filePreviews]);
                

            
          }


          const handleImageClick =  (index) => {
            Swal.fire({
                imageUrl: previews[index].url,
                imageAlt: `Preview ${index}`,
                showCloseButton: true,
                showConfirmButton: false,
                imageWidth: "100%",
                imageHeight: 'auto',
            });
          }
        
          const handlePdfClick = (index) => {
            const pdfUrl = previews[index].url;
            window.open(pdfUrl, '_blank');
        }
        
        const handleVideoClick = (index) => {
          const videoUrl = previews[index].url;
          
          Swal.fire({
            html: `<div style="overflow: hidden;">
                      <video width="100%" height="100%" controls style="margin-top: 15px;"> <!-- Adjust margin-right to accommodate scrollbar -->
                          <source src="${videoUrl}" type="video/mp4">
                          Your browser does not support the video tag.
                      </video>
                  </div>`,
            showCloseButton: true,
            showConfirmButton: false,
            scrollbarPadding: false 
          });
        };

         
        function deleteImage(index){
          const updatedFiles = [...images];
        updatedFiles.splice(index, 1);
        setImages(updatedFiles);
        setPreviews(previews.filter((_, i) => i !== index));
       
       }

    return(

<div  class="d-flex dashboard">

{showComponents && (
 <><SideNav /><TopBar /></>
      )}
      
      
      {isLoading?
     <div className="loader-container" style={{
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      position: "fixed",
      zIndex: "9999",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}


      <div class="right_panel position contact_section vh-100" id="right_panel">

{/* <div class="main_title" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200"> */}
<div class="main_title" >
    <h1><i class="bi bi-car-front-fill"></i> Vehcile List</h1>
    
</div>

<div class="content_wrapper pt-0">
        <div class="content p-0">
            <div class="pb-5" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
            {mergedArray.map(function fn(item, index){  

               return(
                <div class="bg-white mb-4 p-2 p-md-4 shadow-lg">
                    <div class="row">
                        <div class="col-md-5">
                        <Flickity  className={`carousel carousel-main carousel-main${index}`}  options={{pageDots: false ,}} >
                        
                        
                        <div className="carousel-cell"  style={{ background: `url('${placeholderImage}')`, height: "300px" }}> <img src={`${LARAVEL_API_URL}${item.coverImage.filepath}`} alt="Cover" onClick={() => setSelectedImageIndexes(prev => {
  const newArray = [...prev];
  newArray[index] = 0;
  return newArray;
})} /></div>
                       {item.inventoryImages.filepath_compressed.split(",").map((option, optionIndex) => (<div className="carousel-cell" style={{ background: `url('${placeholderImage}')`}}><div key={option}>  <img src={`${LARAVEL_API_URL}${option}`} alt={`Image ${optionIndex}`} onClick={() => setSelectedImageIndexes(prev => {
    const newArray = [...prev];
    newArray[index] = optionIndex + 1;
    return newArray;
})}/></div></div>))}
                        </Flickity>

      <Flickity className="carousel carousel-nav" options={{ asNavFor: `.carousel-main${index}`, contain: true, pageDots: false }}>
      <div className="carousel-cell" style={{ background: `url('${placeholderImage}')`}}> <img src={`${LARAVEL_API_URL}${item.coverImage.filepath}`} alt="Cover" /></div>
                       {item.inventoryImages.filepath_compressed.split(",").map((option, index) => (<div className="carousel-cell" style={{ background: `url('${placeholderImage}')`}}><div key={option}>  <img src={`${LARAVEL_API_URL}${option}`} alt={`Image ${index}`} /></div></div>))}
      </Flickity>

      <Modal
  show={selectedImageIndexes[index] !== undefined}
  onHide={() => setSelectedImageIndexes(prev => {
    const newArray = [...prev];
    newArray[index] = undefined;
    return newArray;
  })}
  
  centered
  size="lg" 
>
    
<Modal.Header closeButton> </Modal.Header>
  <Modal.Body>
    <Carousel
      interval={null}
      activeIndex={selectedImageIndexes[index]}
      onSelect={(selectedIndex) => setSelectedImageIndexes(prev => {
        const newArray = [...prev];
        newArray[index] = selectedIndex;
        return newArray;
      })}
     prevIcon={<span class="carousel-control-prev-icon"  style={{backgroundColor:"white", borderRadius:"10%", color:"black"}}>
        <svg class="flickity-button-icon" viewBox="0 0 100 100"><path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z" class="arrow"></path></svg>  
     </span> } 
     nextIcon={<span class="carousel-control-next-icon"  style={{backgroundColor:"white", borderRadius:"10%", color:"black"}}>
    <svg class="flickity-button-icon" viewBox="0 0 100 100"><path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z" class="arrow" transform="translate(100, 100) rotate(180) "></path></svg> 
     </span> } 
     
    >
       
      <Carousel.Item>
        <img
          src={`${LARAVEL_API_URL}${item.coverImage.filepath}`}
          alt="Cover"
          style={{ objectFit: "contain", maxHeight: "100%", maxWidth: "100%" }}
        />
      </Carousel.Item>
      {item.inventoryImages.filepath_compressed.split(",").map((option, index) => (
        <Carousel.Item key={option}>
          <img
            src={`${LARAVEL_API_URL}${option}`}
            alt={`Image ${index}`}
            style={{ objectFit: "contain", maxHeight: "100%", maxWidth: "100%" }}
          />
          
        </Carousel.Item>
      ))}

      
    </Carousel>
  </Modal.Body>
</Modal>
                        </div>
                        <div class="col-md-7 pt-3 pt-md-0">
                            <div>
                                <h2 class="vm_font_semi_bold lh-base pb-1 border-bottom mb-2"> {item.vehicle_name}  {item.trim}  {item.drive_type} </h2>
                                <div class="d-md-flex justify-content-between">
                                    <div class="lh-1"><i class="bi bi-tags fs-6"></i> <span class="vm_font_semi_bold vm_text_color_secondary fs-6"><span class="currency">CAD</span>{item.price.sale_price.toLocaleString()}</span> + Tax & Lic</div>
                                    <p class="lh-1 pt-2 pt-md-0"><span class="vm_font_semi_bold"><i class="bi bi-speedometer me-1"></i> Milage</span> : {item.odometer.toLocaleString()} Km</p>
                                </div>
                                <p class="text-muted pt-1">7.99% for 84 Months</p>
                                <p class="lh-base py-2">Digital driver console, awd, leather, heated seats, navigation, roof, BT audio and sound, air, tilt, cruise, 4 cylinder economical engine, back up camera and more.</p>
                                <h5 class="vm_font_bold">Features</h5>
                                <div class="d-flex flex-wrap pb-2">
                                {item.inventorySection2.package_options.split(',').concat(item.inventorySection2.technology_options.split(',')).map((option) => (
                                    <p class="border px-2 me-2 mb-2">{option}</p>
                                    ))}
                                  
                                </div>
                                <div class="row mt-2 border-top pb-3">
                                    <div class="d-flex border-bottom">
                                        <div class="col p-1 border-end vm_font_bold">Body Style:</div>
                                        <div class="col p-1 ps-3">{item.body_type}</div>
                                    </div>
                                    <div class="d-flex border-bottom"> 
                                        <div class="col p-1 border-end vm_font_bold">Engine:</div>
                                        <div class="col p-1 ps-3">{item.engine_type}</div>
                                    </div>
                                    <div class="d-flex border-bottom">
                                        <div class="col p-1 border-end vm_font_bold">Exterior Colour:</div>
                                        <div class="col p-1 ps-3">{item.exterior_color}</div>
                                    </div>
                                    <div class="d-flex border-bottom">
                                        <div class="col p-1 border-end vm_font_bold">Transmission:</div>
                                        <div class="col p-1 ps-3">{item.transmission}</div>
                                    </div>
                                    <div class="d-flex border-bottom">
                                        <div class="col p-1 border-end vm_font_bold">Drive Train:</div>
                                        <div class="col p-1 ps-3">{item.drive_type}</div>
                                    </div>
                                    <div class="d-flex border-bottom">
                                        <div class="col p-1 border-end vm_font_bold">Doors:</div>
                                        <div class="col p-1 ps-3">{item.door}</div>
                                    </div>
                                    <div class="d-flex border-bottom">
                                        <div class="col p-1 border-end vm_font_bold">Stock #:</div>
                                        <div class="col p-1 ps-3">{item.stock_no}</div>
                                    </div>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                   
                </div>
 );
})}
           

               
            </div>
        </div>
    
</div>
</div>

{showComponents && (
      <div class="bg-dark position-fixed d-flex justify-content-end bottom-0 px-2 py-2 w-100">
    <div class="modal-footer send_option_wrapper">
        <div class="option d-flex">
        <input
          className="d-none"
          type="checkbox"
          id="email_send"
          onChange={(e) => setEmail_toggle_selected(e.target.checked)}
        />
            <label class="py-1 vm_cursor_pointer me-2 d-flex align-items-center" for="email_send">
                <i class="bi bi-toggle-off"></i>
                <i class="bi bi-toggle-on text-success"></i>
                Email
            </label>
            <input class="d-none" type="checkbox" id="sms_send" onChange={(e) => setSms_toggle_selected(e.target.checked)}/>
            <label class="py-1 vm_cursor_pointer me-2 d-flex align-items-center" for="sms_send">
                <i class="bi bi-toggle-off"></i>
                <i class="bi bi-toggle-on text-success"></i>
                SMS
            </label>
        </div>
        <button class="btn btn-sm btn_secondary text-white" data-bs-toggle="modal" data-bs-target="#exampleModal"  disabled={loading || (!email_toggle_selected && !sms_toggle_selected)} onClick={() =>{setShowModal(true); setEmail('');
        setSuccessMessage();}}><i class="bi bi-send"></i> Send</button>
    </div>
</div>
)};


<div class="modal fade"  id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          {/* <h2 class="modal-title" id="exampleModalLabel">Send Email</h2> */}
          <button type="button"  class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() =>setShowModal(false)}></button>
        </div>
        <div className="modal-body">

        <ul className="nav nav-tabs" id="messageTabs" role="tablist">
        {email_toggle_selected && (
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${activeTab === 'email' ? 'active' : ''}`} id="email-tab" data-bs-toggle="tab" data-bs-target="#email" type="button" role="tab" aria-controls="email"  aria-selected={activeTab === 'email' ? 'true' : 'false'} onClick={() => setActiveTab('email')}>Email</button>
          </li>
            )}
            {sms_toggle_selected && (
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${activeTab === 'sms' ? 'active' : ''}`} id="sms-tab" data-bs-toggle="tab" data-bs-target="#sms" type="button" role="tab" aria-controls="sms"   aria-selected={activeTab === 'sms' ? 'true' : 'false'} onClick={() => setActiveTab('sms')}>SMS</button>
          </li>
            )}
        </ul>
        <div className="tab-content" id="messageTabsContent">
        {email_toggle_selected && (
          <div className={`tab-pane fade ${activeTab === 'email' ? 'show active' : ''}`} id="email" role="tabpanel" aria-labelledby="email-tab">

          <form >
          <div className="py-2 mt-2 bg-light">
          <div className="form-group pb-4 px-3" >  <label for="recipient-name" class="col-form-label">To</label>  <input class="form-control" type="text"  value={customerEmail?customerEmail:email} onChange={(e) => setEmail(e.target.value)}  placeholder="Enter recipient email (separated by commas)"/>
          <span  className="text-danger">{email_error_list.to}</span></div>
          <div className="form-group pb-4 px-3">  <label for="subject" class="col-form-label">Subject</label> <input class="form-control" type="text" value={subject}  onChange={(e) => setSubject(e.target.value)}  />
          <span className="text-danger">{email_error_list.subject}</span> 
          </div>
          <div className="form-group pb-4 px-3">
          <label for="message" className="col-form-label">Message</label>
         
          {/* <div
    class="form-control"
    contentEditable="true"
    style={{ minHeight: "100px" }} dangerouslySetInnerHTML={{ __html: message }}
    onChange={(e) => setMessage(e.target.innerHTML)}
    required >
    
  </div> */}
           <textarea class="form-control" rows="4" value={message} onChange={(e) => setMessage(e.target.value)}  />
           <span  class="text-danger">{email_error_list.message}</span>
           </div>
           <div class="d-flex align-items-center justify-content-between">
         
           <div class="d-flex align-items-center" style={{paddingLeft:"20px"}}>
                                <label class="vm_cursor_pointer" for="image"><i class="bi bi-card-image h2"></i></label>
                                <input class="d-none" type="file" name="image" id="image" multiple onChange= {onSelectImages}/>
           <select class="form-select ms-3 form-select-sm" name="template" id="template" value={template} onChange={handleTemplateChange}>
                                    <option value="">Select Template</option>
                                    {email_template.map((item) => (
                                    <option value={item.template_name}>{item.template_name}</option>
                                    ))}
                                </select>
                                </div>
          
            
            <div class="ms-auto me-3"> 
            {/* {successMessage && <p class="text-left" style={{color:"green",flex: "1" }}>{successMessage}</p>} */}
            <button  onClick={sendEmail} class="btn btn-sm btn_secondary text-white ms-md-4"> {loading?"Sending...":"Send"}</button></div>
            </div>
            <div className="container"  style={{ maxHeight: "200px", overflowY: "auto", display: "flex" , padding:"15px"}}>
                        { previews.length > 0 && previews.map((preview, index) => (
                                                <div key={index} style={{ position: "relative", marginRight: "5px", marginBottom: "8px", height: "75px", width: "75px" }}  >
                                                   <span  style={{position:"absolute",cursor: "pointer",top: "-2px",right: "2px", fontSize: "20px", color:"limegreen"}}
                                                   className="delete" onClick={() => deleteImage(index)}>&times;</span>
                                                {preview.type === 'image' && (
                                                <img
                                                    loading="lazy"
                                                    onClick={() => handleImageClick(index)}  
                                                    style={{ width: "100%", height: "100%", borderRadius: "5px" }}
                                                    src={preview.url}
                                                    alt={`Preview ${index}`}
                                                />
                                            )}
                                    
                                    {preview.type === 'pdf' && (
                                                
                                                    <img src="/img/pdf_icon.jpg" alt={`Preview ${index}`}  onClick={() => handlePdfClick(index)} 
                                                    style={{ width: "100%", height: "100%", borderRadius: "5px" }}/>
                                                
                                             
                                            )}
                                    
                                    {preview.type === 'video' && (
                                             
                                                <video  src={preview.url} alt={`Preview ${index}`}  onClick={() => handleVideoClick(index)} 
                                                style={{ width: "100%", height: "100%", borderRadius: "5px" }}/>
                                           
                                        )}
                                                </div>
                                            ))}
                                        </div>
            </div>
          </form>

          </div>
            )}
             {sms_toggle_selected && (
          <div className={`tab-pane fade ${activeTab === 'sms' ? 'show active' : ''}`} id="sms" role="tabpanel" aria-labelledby="sms-tab">
            <form >
            <div class="py-2 mt-2  bg-light">

            <div class="form-group pb-4 px-3">  <label for="recipient-to" class="col-form-label">To</label>  
            <input class="form-control" type="text"  value={customerPhoneNo?customerPhoneNo:phone_no} onChange={(e) => setPhone_no(e.target.value)} placeholder="Enter Phone no "/>
            <span  class="text-danger">{error_list.phone_no}</span>
            </div>

                        <div class="form-group pb-4 px-3">
                            <label for="tophoneNo">Text Message</label>
                            <textarea class="mh-100 form-control" name="text_message" id="text_message" cols="100%" rows="4" value={text_message}  placeholder="Enter Text Message"
                            onChange={(event) =>{ setText_message(event.target.value); }}></textarea>
                             <span  class="text-danger">{error_list.message}</span>
                        </div>
                        <div class="d-md-flex">
                        <div>
                            <select class="form-select ms-3 form-select-sm" name="text_template" id="text_template" value={text_template} onChange={handleTextTemplateChange}>
                                    <option value="">Select Template</option>
                                    {sms_template.map((item) => (
                                    <option value={item.template_name}>{item.template_name}</option>
                                    ))}
                                </select>
                            </div>
                            
                            <div class="ms-auto me-3">
                                <button onClick={sendSMS}  class="btn btn-sm btn_secondary text-white ms-md-4"><i class="bi bi-send"></i>{loading?' Sending...':' Send SMS'}</button>
                            </div>
                        </div>
                       

                        
                    </div>
            </form>
          </div>
          )}
</div>



        </div>
      </div>
    </div>
  </div>

     
      
</div>
    );
}
 export default VehicleList;