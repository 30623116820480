// SettingMeetingTemplate.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SideNav from "./SideNav";
import TopBar from "./TopBar";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from "@fullcalendar/interaction";


import Swal from 'sweetalert2';

function SettingMeetingTemplate() {

    const API_URL = process.env.REACT_APP_API_URL;
    const [events, setEvents] = useState([]);
    const [title, setTitle] = useState('');
    const [startDatetime, setStartDatetime] = useState('');
    const [endDatetime, setEndDatetime] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [loading, setLoading] = useState(false);
    const [error_list, setErrorList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [availableTimeSlots, setAvailableTimeSlot] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        fetchEvents();
    }, []); // Empty dependency array ensures the effect runs only once, similar to componentDidMount

    function fetchEvents1() {
     
            axios.get(`${API_URL}/view-meet-template`).then(res=>{
                if(res.data.status === 200){
                    const formattedEvents = res.data.meetTemplate.map(event => ({
                        ...event,
                        start: new Date(event.start_datetime),
                        end: new Date(event.end_datetime)
                    }));
                    setEvents(formattedEvents);

                }
        else if(res.data.status === 404){
            setEvents([]);    
              }
              setIsLoading(false);
            });  
    
    }

    function fetchEvents() {
     
        axios.get(`${API_URL}/view-meet-template`).then(res=>{
            if(res.data.status === 200){
                const formattedEvents =  res.data.meet.map(meeting => ({
                    title: meeting.title,
                    start: meeting.date + 'T' + meeting.time // Combine date and time into a single string
                }));
                setEvents(formattedEvents);

            }
    else if(res.data.status === 404){
        setEvents([]);    
          }
          setIsLoading(false);
        });  

}

const handleDateChange = (e) => {
       
    const selectedDate = e.target.value;
    setDate(selectedDate);
    axios.get(`${API_URL}/booked-appointments`, { params: { selectedDate } })
    .then(response => {
        const bookedTimes = response.data.map(time => {
            const [hour, minute] = time.split(':');
            const hour12 = (parseInt(hour) % 12) || 12;
            const ampm = parseInt(hour) < 12 ? 'AM' : 'PM';
            return `${hour12}:${minute} ${ampm}`;
        });

        const availableTimes = generateTimeSlots(bookedTimes);
        // console.log(bookedTimes);
    })
    .catch(error => {
        console.error('Error fetching available date-time pairs:', error);
    });
  };

  const generateTimeSlots = (bookedTimes) => {
    // Generate time slots from 9 AM to 4 PM
    const startTime = 9;
    const endTime = 16;
    const timeSlots = [];

    for (let hour = startTime; hour <= endTime; hour++) {
      const formattedHour = hour % 12 || 12;
      const ampm = hour < 12 ? 'AM' : 'PM';
      const timeSlot = `${formattedHour}:00 ${ampm}`;
      timeSlots.push(timeSlot);
    }


   // console.log(timeSlots.filter(timeSlot => !timeSlot.includes(bookedTimes)))

    const availableTimeSlots = timeSlots.filter(timeSlot => !bookedTimes.includes(timeSlot));
setAvailableTimeSlot(availableTimeSlots);
    return availableTimeSlots;
  };




    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
       

            await axios.post(`${API_URL}/save-meet-template`, {
                title,
                start_datetime: startDatetime,
                end_datetime: endDatetime
            }).then(res =>{
           
                Swal.fire({
                    icon:"success",
                    text:res.data.message
                  }).then(function(){ 
                    setLoading(false);        
                    resetData();
                    setErrorList([]); 
                    fetchEvents();
                });
                }).catch(function(error) {
                    setLoading(false);
                    setErrorList(error.response.data.validate_err);
                    Swal.fire({
                      text:error.response.data.message,
                      icon:"error"
                    })
                })

        }


    const resetData = () => {
        setTitle('');
        setStartDatetime('');
        setEndDatetime('');
    };

    const handleDateClick = (arg) => {
        const eventTitle = prompt('Please enter the title for the event:');
        if (eventTitle) {
            const newEvent = {
                title: eventTitle,
                start: arg.date,
                allDay: true
            };
            // Update events state with the new event
            setEvents([...events, newEvent]);
        }
    };

  
    return (
        <div>
            <SideNav />
            <TopBar />
            {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}

            <div className="right_panel position contact_section vh-100" id="right_panel">
                <div className="main_title d-flex align-items-center">
                    <h1><i className="bi bi-calendar-check"></i> Create Meeting</h1>
                </div>

                <div className="content_wrapper pt-0">
                    <div className="vm_content_box_1">
                        <div className="title">
                            <h2>Create Template</h2>
                        </div>
                        <div className="content pt-3 pb-5 mb-5">
                          
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="meeting_title">Enter Subject</label>
                                            <input type="text" className="form-control" id="meeting_title" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Ex: Meeting..." />
                                            <span class="text-danger">{error_list.title}</span>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="start_datetime">Select start Date and time</label>
                                            <div class="d-flex input_wrapper">
                                                <div class="icon_wrapper">
                                                    <i class="bi bi-calendar2-plus icon"></i>
                                                </div>
                                                <input type="datetime-local" className="form-control" id="start_datetime" value={startDatetime} onChange={(e) => setStartDatetime(e.target.value)} />
                                               
                                            </div>
                                            <span class="text-danger">{error_list.start_datetime}</span>
                                        </div>
                                    </div> */}

  <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="start_datetime">Select Date</label>
                                            <div class="d-flex input_wrapper">
                                                <div class="icon_wrapper">
                                                    <i class="bi bi-calendar2-plus icon"></i>
                                                </div>
          <input type="date" id="date" value={date} className="form-control" onChange={handleDateChange}  />
     
                                            </div>
                                            <span class="text-danger">{error_list.date}</span>
                                        </div>
                                    </div>

                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="end_datetime">Select End date and time</label>
                                            <div class="d-flex input_wrapper">
                                                <div class="icon_wrapper">
                                                    <i class="bi bi-calendar2-plus icon"></i>
                                                </div>
                                                <input type="datetime-local" className="form-control" id="end_datetime" value={endDatetime} onChange={(e) => setEndDatetime(e.target.value)} />
                                             
                                            </div>
                                            <span class="text-danger">{error_list.end_datetime}</span>
                                        </div>
                                    </div> */}

<div className="col-md-6">
                                        <div className="form-group">
                                        <label htmlFor="time">Select Time:</label>
          <select id="time" value={time} className="form-control" onChange={(e) => setTime(e.target.value)} >
            <option value="">Select Time</option>
            {availableTimeSlots.map((timeSlot, index) => (
              <option key={index} value={timeSlot}>{timeSlot}</option>
            ))}
          </select>
          <span class="text-danger">{error_list.time}</span>


                                        </div>
                                    </div>




                                </div>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div>
                                        <button class="btn btn-sm btn_secondary text-white"><i class="bi bi-save me-2"></i>{loading ? "Saving..." : "Save Meeting"} </button>
                                    </div>
                                </div>
                         
                        </div>
                        <div id="calender"  style={{ boxShadow: "0px 0px 21px 2px rgba(0, 0, 0, 0.18)",backgroundColor:"white"}}  >
                         {/* style={{ boxShadow: "0px 0px 21px 2px rgba(0, 0, 0, 0.18)",backgroundColor:"white"}}  */}

                        <FullCalendar
                                plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
                                initialView="dayGridMonth"
                                events={events}
                                headerToolbar={{
                                    left: 'prev,next today',
                                    center: 'title',
                                    right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                                }}
                                themeSystem="bootstrap-premium"
                                height="auto"
                                contentHeight="auto"
                                eventColor="#03a9f3" // Set event color
                                eventTextColor="#ffffff" // Set event text color
                                // dateClick={handleDateClick} 
                            />

                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default SettingMeetingTemplate;
