import { useState, useEffect, useRef } from "react";
import SideNav from "./SideNav";
import TopBar from "./TopBar";
import axios from 'axios';
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
import {Link, useParams, useNavigate} from "react-router-dom";

function SettingSmsTemplate(){


    const API_URL = process.env.REACT_APP_API_URL;
    const [template_name, setTemplate_name] = useState("");
    const [message, setMessage] = useState("");
  
    const [error_list, setErrorList] = useState([]);
    const [sms_template, setSms_template] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const scrollToRef = useRef(null);

    const filteredResult = sms_template.filter((item) => {
        return item.template_name.toLowerCase().includes(searchQuery.toLowerCase()) 
      });
      

      const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
      };

      useEffect(() => {
        setIsLoading(true);
        getSmsTemplateList();
       
    }, []);

    function getSmsTemplateList()
      {
        
        axios.get(`${API_URL}/view-sms-template`).then(res=>{
            if(res.data.status === 200){
                setSms_template(res.data.smsTemplate);    
                scrollToRef.current.scrollIntoView({ behavior: "smooth" });            
            }     
            else if(res.data.status === 404){
              setSms_template([]);
              //  setMessage(res.data.message);     
                }
                setIsLoading(false);
         });  
       
      }

      const deleteSmsTemplate = (e,id) =>{
        e.preventDefault();
        var buttonClicked = window.confirm("Are you sure you want to delete SMS template ?");
        if(buttonClicked == true){
           // const thisClicked = e.currentTarget;
           // thisClicked.innerText = "Deleting";
            axios.delete(`${API_URL}/delete-sms-template/`+id).then(res => {
             
          Swal.fire({
                  icon:"success",
                  text:res.data.message
                }).then(function(){
                   // thisClicked.closest("tr").remove();  
                    
              });

               getSmsTemplateList();
            })
        }
        else
        {
            getSmsTemplateList();
        }
       
       }

    

     async function save(e){
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append("template_name", template_name);
        formData.append("message", message);
            
          await axios.post(`${API_URL}/save-sms-template`, formData).then(res =>{
           
            Swal.fire({
                icon:"success",
                text:res.data.message
              }).then(function(){ 
                setLoading(false);        
                resetData();
                setErrorList([]);                           
                 getSmsTemplateList();
                   
            });
            }).catch(function(error) {
                setLoading(false);
                setErrorList(error.response.data.validate_err);
                Swal.fire({
                  text:error.response.data.message,
                  icon:"error"
                })
            })
       }

       const resetData = () => {
        setTemplate_name("");
        setMessage("");
        };
    
       


    return(
<div>
    <SideNav/>
    <TopBar/>
    {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}

 <div class="right_panel position contact_section vh-100" id="right_panel" ref={scrollToRef}>

        <div class="main_title d-flex aling-items-center justify-content-start">
            <i class="bi bi-chat-right-dots"></i>
            <h1>Create New SMS Template</h1>
        </div>

        <div class="content_wrapper pt-0">
            <div class="vm_content_box_1">
                <div class="title">
                    <h2>Create Template</h2>
                </div>
                <div class="content pt-3">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="template_name">Template Name</label>
                                <input type="text" class="form-control" name="template_name"  id="template_name"  value={template_name} placeholder="Enter Template Name"
                                 onChange={(event) =>{ setTemplate_name(event.target.value); }}/>
                                      <span class="text-danger">{error_list.template_name}</span>
                            </div>
                        </div>
                    
                    </div>
                  
                    <div class="col-md-12">
                        <div class="form-group">
                            <div class="d-flex justify-content-between">
                                <label for="lotlocation">Generate AI</label>
                                <button class="btn_secondary text-white mb-1 py-0">Generate AI</button>
                            </div>
                            <textarea class="form-control h-100" name="generate_ai" id="generate_ai" cols="" rows="5" placeholder="Ex: Birthday Greeting..."></textarea>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="lotlocation">Template Message</label>
                            <textarea class="form-control h-100" name="message" id="message" cols="" rows="5" value={message} placeholder="Template Message"
                            onChange={(event) =>{ setMessage(event.target.value); }} />
                                 <span class="text-danger">{error_list.message}</span>
                        </div>
                    </div>
                    <div class="d-md-flex align-items-center justify-content-between">
                       
                        <div>

                            <button onClick={save} class="btn btn-sm btn_secondary text-white"><i class="bi bi-save me-2"></i> {loading?"Saving":"Save"}</button>
                        </div>                     
                    </div>

                                        
                </div>
            </div>

            <div class="vm_content_box_1 mt-4">
                <div class="title">
                    <h2>SMS Templates</h2>
                </div>

                <div class="content">
                <div class="row pt-3">
                          
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                              
                   
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search Email Templates" type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                          </div>




                          <> <table id="example" class="display dataTable dtr-inline" style={{width:"100%"}}>
                        <thead>
                            <tr>
                                <th>Template Name</th>
                                <th class="text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                        {filteredResult.length == 0 ?
        <table class="display dataTable dtr-inline">
        <tbody>
          <tr>
            <div class="row" style={{margin: "10px -400px 10px 10px"}}>
                       <p  valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                       </div>
          </tr>
        </tbody>
      </table>
      :    null }


{filteredResult.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
                                            <tr key={index}>

        <td>
            <p>{item.template_name}</p>
        </td>
        <td class="d-flex justify-content-end">

                                                    <Link to={`/edit-sms-template/${item.id}`} class="btn btn-sm btn_secondary" >
                                                        <i class="bi bi-pencil-square"></i> Edit</Link>

                                                    <button class="btn btn-sm btn_secondary" style={{ background: "red", color: "white", margin: "0px 0px 0px 10px" }}
                                                        onClick={(e) => deleteSmsTemplate(e, item.id)}><i class="bi bi-trash3"></i> Delete</button>
                                                </td>
   
            
        </tr>
        ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>Template Name</th>
                                <th class="text-end">Action</th>
                            </tr>
                        </tfoot>
                    </table><div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                    {filteredResult.length > 0 && `Showing 1 to ${Math.min(perPage, filteredResult.length)} of ${filteredResult.length} entries`}
                </div><div class="col-md-9" style={{ float: "right" }}>

                    <ReactPaginate style={{ float: "right" }}
                        previousLabel={"< Prev"}
                        nextLabel={"Next >"}
                        breakLabel={'...'}
                        pageCount={Math.ceil(filteredResult.length / perPage)}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                        disabledClassName={"disabled"}
                        breakClassName={['page-item']}
                        breakLinkClassName={'page-link'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        activeClassName={['active']} />


                </div></>
                </div>
            </div>

        </div>
        

</div>
</div>
    );
}
export default SettingSmsTemplate;