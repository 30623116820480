import { useState, useEffect, useRef } from "react";
import SideNav from "./SideNav";
import TopBar from "./TopBar";
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Swal from 'sweetalert2';

function CreateCustomer(){

    const API_URL = process.env.REACT_APP_API_URL;
    const [user_type, setUser_type] = useState("");
    const [first_name, setFirst_name] = useState("");
    const [last_name, setLast_name] = useState("");
    const [phone_no, setPhone_no] = useState("");
    const [email, setEmail] = useState("");
    const [purchased_on, setPurchased_on] = useState(new Date());
    const [country, setCountry] = useState("");
    
    const [address, setAddress] = useState("");
    const [apart_or_suite, setApart_or_suite] = useState("");
    const [city, setCity] = useState("");
    const [province, setProvince] = useState("");
    const [postal_code, setPostal_code] = useState("");
    const [employer_name, setEmployer_name] = useState("");
    const [employer_address, setEmployer_address] = useState("");

    
    const [employer_country, setEmployer_country] = useState("");
    const [employer_apart_or_suite, setEmployer_apart_or_suite] = useState("");
    const [employer_city, setEmployer_city] = useState("");
    const [employer_state, setEmployer_state] = useState("");
    const [employer_postalcode, setEmployer_postalcode] = useState("");
    const [job_title, setJob_title] = useState("");
    const [monthly_income, setMonthly_income] = useState("");
    const [vehicle_type, setVehicle_type] = useState("");
    const [hear_about, setHear_about] = useState("");
    const [error_list, setErrorList] = useState([]);
    const [file, setFile] = useState("");
    const fileInputRef = useRef(null);
    const [loading, setLoading] = useState(false);


    const handleUserType = (event) => {
        setUser_type(event.target.value);
    };

    const handleCountryChange = (event) => {
        setCountry(event.target.value);
    };

    const handleEmployerCountry = (event) => {
        setEmployer_country(event.target.value);
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]); // Store the file in the component state
       
      };
    useEffect(() => { 
        AOS.init({ duration: 1200 });

}, []);


async function save(event)
{
  event.preventDefault();
  setLoading(true);
  const formData = new FormData()
    formData.append('file', file);
    formData.append('user_type', user_type)
    formData.append('first_name', first_name)
    formData.append('last_name', last_name)
    formData.append('phone_no', phone_no)
    formData.append('email', email)
    formData.append('purchased_on', purchased_on)
    formData.append('country', country)
    formData.append('address', address)
    formData.append('apart_or_suite', apart_or_suite)
    formData.append('city', city)
    formData.append('province', province)
    formData.append('postal_code', postal_code)
    formData.append('employer_name', employer_name)
    formData.append('employer_address', employer_address)
    formData.append('employer_country', employer_country)
    formData.append('employer_apart_or_suite', employer_apart_or_suite)
    formData.append('employer_city', employer_city)
    formData.append('employer_state', employer_state)
    formData.append('employer_postalcode', employer_postalcode)
    formData.append('job_title', job_title)
    formData.append('monthly_income', monthly_income)
    formData.append('vehicle_type', vehicle_type)
    formData.append('hear_about', hear_about)

 console.log(formData);
      await axios.post(`${API_URL}/save-customer`, formData).then(res =>{

       
        Swal.fire({
            icon:"success",
            text:res.data.message
          }).then(function(){     
            setLoading(false);        
               resetData();
               setErrorList([]);
             
               //getCustomerList();
               
        });
        }).catch(function(error) {
          // if(error.response.status===422){    
             
          setErrorList(error.response.data.validate_err);
          // }
          // else{
            Swal.fire({
              text:error.response.data.message,
              icon:"error"
            })
            setLoading(false);    
          // }
        })
       
}

const resetData = () => {
    setFile("");
    fileInputRef.current.value = null;
    setUser_type("");
    setFirst_name("");
    setLast_name("");
    setPhone_no("");
    setEmail("");
    setPurchased_on("");
    setCountry("");
    setAddress("");
    setApart_or_suite("");
    setCity("");
    setProvince("");
    setPostal_code("");
    setEmployer_name("");
    setEmployer_address("");
    setEmployer_country("");
    setEmployer_apart_or_suite("");
    setEmployer_city("");
    setEmployer_state("");
    setEmployer_postalcode("");
    setJob_title("");
    setMonthly_income("");
    setVehicle_type("");
    setHear_about("");
    
    };


    return(
        <div>
             <SideNav/>
             <TopBar/>
             
             <div class="right_panel position create_inventory h-100" id="right_panel">
  
        <div class="main_title" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
            <h1><i class="bi bi-plus-lg h1"></i> Add New Customer</h1>
        </div>

        <div class="content_wrapper pt-0">
          
            <div id="inventory_section1">
                <div class="col" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
                    <div class="vm_content_box_1">
                        <div class="title">
                            <h2><i class="bi bi-person"></i> Personal Information</h2>
                        </div>
                        <div class="content pt-3">
                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <label for="firstname">Add Photo</label>
                                    <input type="file" class="form-control vm_bg_secondary text-white"  ref={fileInputRef} name="customer_photo" id="customer_photo"  onChange={handleFileChange} />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="empcountry">Select User Type</label>
                                    <select class="form-select" name="user_type" id="user_type" value={user_type} onChange={handleUserType}>
                                    <option value="">Select User Type</option>
                                        <option value="Leads">Leads</option>
                                        <option value="Customer">Customer</option>
                                    </select>
                                    <span class="text-danger">{error_list.user_type}</span>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="firstname">First Name</label>
                                    <input type="text" class="form-control" name="first_name" id="first_name" placeholder="Enter Your First Name" value={first_name} 
                                    onChange={(event) =>{ setFirst_name(event.target.value);}}/>
                                     <span class="text-danger">{error_list.first_name}</span>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="lastname">Last Name</label>
                                    <input type="text" class="form-control" name="last_name" id="last_name" placeholder="Enter Your Last Name" value={last_name}
                                     onChange={(event) =>{ setLast_name(event.target.value);}}/>
                                        <span class="text-danger">{error_list.last_name}</span>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="phone">Phone Number</label>
                                    <input type="text" class="form-control" name="phone" id="phone" placeholder="Enter Your Phone Number" value={phone_no}
                                    onChange={(event) => { setPhone_no(event.target.value);} }/>
                                       <span class="text-danger">{error_list.phone_no}</span>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="email">Email Address</label>
                                    <input type="text" class="form-control" name="email" id="email" value={email} placeholder="Enter Your Email Address"
                                     onChange={(event) => { setEmail(event.target.value); }}/>
                                        <span class="text-danger">{error_list.email}</span>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="Purchasedon_date">Purchased on</label>
                                    <div class="d-flex input_wrapper">
                                        <div class="icon_wrapper">
                                            <i class="bi bi-calendar2-plus icon"></i>
                                        </div>
                                        <input type="date" class="form-control" name="purchased_on" id="purchased_on" placeholder="Select Date" value={purchased_on}  
                                        onChange={(event) =>{ setPurchased_on(event.target.value);} }/>
                                    
                                    </div>
                                    <span class="text-danger">{error_list.purchased_on}</span>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="country">Country</label>
                                    <select class="form-select" name="country" id="country"  value={country} onChange={handleCountryChange}>
                                    <option value="">Select Country</option>
                                        <option value="Canada">Canada</option>
                                        <option value="United States">United States</option>
                                    </select>
                                    <span class="text-danger">{error_list.country}</span>
                                </div>
                                <div class="col-md-12 form-group">
                                    <label for="address">Address</label>
                                    <input type="text" class="form-control" name="address" id="address" value={address} placeholder="Address"
                                    onChange={(event) => { setAddress(event.target.value); }}/>
                                         <span class="text-danger">{error_list.address}</span>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="apart_or_suite">Apt/Suite/Other</label>
                                    <input type="text" class="form-control" name="apart_or_suite" id="apart_or_suite" value={apart_or_suite}  placeholder="Apt/Suite/Other"
                                    onChange={(event) => { setApart_or_suite(event.target.value); }}/>
                                         <span class="text-danger">{error_list.apart_or_suite}</span>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="city">City</label>
                                    <input type="text" class="form-control" name="city" id="city" value={city}  placeholder="Enter Your City" 
                                    onChange={(event) => { setCity(event.target.value); }}/>
                                     <span class="text-danger">{error_list.city}</span>
                                </div>
                                <div class="col-md-6 form-group">
                                <label for="province">State</label>
                                    <input type="text" class="form-control" name="province" value={province} id="province" placeholder="State"
                                     onChange={(event) => { setProvince(event.target.value); }}/>
                                    <span class="text-danger">{error_list.province}</span>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="postalcode">Postal Code</label>
                                    <input type="text" class="form-control" name="postal_code" id="postal_code"  value={postal_code} placeholder="Postal Code"
                                     onChange={(event) => { setPostal_code(event.target.value); }}/>
                                    <span class="text-danger">{error_list.postal_code}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col ms-lg-4 pt-4 pt-lg-0" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
                    <div class="vm_content_box_1 h-100">
                        <div class="title">
                            <h2><i class="bi bi-person"></i> Employer Information</h2>
                        </div>
                        <div class="content pt-3">
                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <label for="employer_name">Employer Name</label>
                                    <input type="text" class="form-control" name="employer_name" id="employer_name" value={employer_name}  placeholder="Employer Name"
                                     onChange={(event) => { setEmployer_name(event.target.value);}}/>
                                      <span class="text-danger">{error_list.employer_name}</span>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="employer_address">Employer Address</label>
                                    <input type="text" class="form-control" name="employer_address" id="employer_address" value={employer_address} placeholder="Employer Address"
                                     onChange={(event) => { setEmployer_address(event.target.value);}}/>
                                     <span class="text-danger">{error_list.employer_address}</span>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="empcountry">Country</label>
                                    <select class="form-select" name="employer_country" id="employer_country" value={employer_country} onChange={handleEmployerCountry}>
                                    <option value="">Select Country</option>
                                        <option value="Canada">Canada</option>
                                        <option value="United States">United States</option>
                                    </select>
                                    <span class="text-danger">{error_list.employer_country}</span>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="employer_apart_or_suite">Apt/Suite/Other</label>
                                    <input type="text" class="form-control" name="employer_apart_or_suite" id="employer_apart_or_suite" value={employer_apart_or_suite} placeholder="Apt/Suite/Other"
                                    onChange={(event) => { setEmployer_apart_or_suite(event.target.value); }}/>
                                   <span class="text-danger">{error_list.employer_apart_or_suite}</span>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="employer_city">City</label>
                                    <input type="text" class="form-control" name="employer_city" id="employer_city" value={employer_city} placeholder="Enter Your City"
                                    onChange={(event) => { setEmployer_city(event.target.value); }}/>
                                     <span class="text-danger">{error_list.employer_city}</span>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="empstate">State</label>
                                    <input type="text" class="form-control" name="employer_state" id="employer_state" value={employer_state} placeholder="State"
                                     onChange={(event) => { setEmployer_state(event.target.value); }}/>
                                       <span class="text-danger">{error_list.employer_state}</span>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="employer_postalcode">Postal Code</label>
                                    <input type="text" class="form-control" name="employer_postalcode" id="employer_postalcode" value={employer_postalcode} placeholder="Enter Your Postal Code"
                                     onChange={(event) => { setEmployer_postalcode(event.target.value); }}/>
                                       <span class="text-danger">{error_list.employer_postalcode}</span>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="job_title">Job Title</label>
                                    <input type="text" class="form-control" name="job_title" id="job_title" value={job_title} placeholder="Enter Your Job Title"
                                     onChange={(event) => { setJob_title(event.target.value); }}/>
                                       <span class="text-danger">{error_list.job_title}</span>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="monthly_income">Monthly Income</label>
                                    <input type="text" class="form-control" name="monthly_income" id="monthly_income" value={monthly_income} placeholder="Enter Your Monthly Income"
                                      onChange={(event) => { setMonthly_income(event.target.value); }}/>
                                         <span class="text-danger">{error_list.monthly_income}</span>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="vehicle_type">Vehicle Type</label>
                                    <input type="text" class="form-control" name="vehicle_type" id="vehicle_type" placeholder="Vehicle Type" value={vehicle_type} 
                                    onChange={(event) => { setVehicle_type(event.target.value); }}/>
                                       <span class="text-danger">{error_list.vehicle_type}</span>
                                </div>
                                <div class="col-md-12 form-group">
                                    <label for="hear_about">How did you hear about us</label>
                                    <input type="text" class="form-control" name="hear_about" id="hear_about" value={hear_about} placeholder="How did you hear about us"
                                    onChange={(event) => { setHear_about(event.target.value); }}/>
                                    <span class="text-danger">{error_list.hear_about}</span>
                                </div>
                            </div>
                           
                                <button class="btn btn-sm btn_secondary"  onClick={save}>{loading?"Saving":"Save"}</button>
                           
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
        </div>
    );
}
export default CreateCustomer;