import { useState, useEffect, useRef } from "react";
import SideNav from "./SideNav";
import TopBar from "./TopBar";
import axios from 'axios';
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
import {Link, useParams, useNavigate} from "react-router-dom";

function SettingNoteTemplate(){

    const API_URL = process.env.REACT_APP_API_URL;
    const [note_title, setNote_title] = useState("");
    const [note_message, setNote_message] = useState("");

    const [error_list, setErrorList] = useState([]);
    const [note_template, setNote_template] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const scrollToRef = useRef(null);
    const [subject, setSubject] = useState("");

    const filteredResult = note_template.filter((item) => {
        return item.note_title.toLowerCase().includes(searchQuery.toLowerCase()) 
      });
      

      const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
      };

      useEffect(() => {
        setIsLoading(true);
        getNoteTemplateList();
       
    }, []);

    function getNoteTemplateList()
      {
        
        axios.get(`${API_URL}/view-note-template`).then(res=>{
            if(res.data.status === 200){
                setNote_template(res.data.noteTemplate);    
                scrollToRef.current.scrollIntoView({ behavior: "smooth" });            
            }     
            else if(res.data.status === 404){
              setNote_template([]);  
                }
                setIsLoading(false);
         });  
       
      }

      const deleteNoteTemplate = (e,id) =>{
        e.preventDefault();
        var buttonClicked = window.confirm("Are you sure you want to delete Note template ?");
        if(buttonClicked == true){
           // const thisClicked = e.currentTarget;
           // thisClicked.innerText = "Deleting";
            axios.delete(`${API_URL}/delete-note-template/`+id).then(res => {
             
          Swal.fire({
                  icon:"success",
                  text:res.data.message
                }).then(function(){
                   // thisClicked.closest("tr").remove();  
                    
              });

               getNoteTemplateList();
            })
        }
        else
        {
            getNoteTemplateList();
        }
       
       }

    

     async function save(e){
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append("note_title", note_title);
        formData.append("subject", subject);
        formData.append("note_message", note_message);
            
          await axios.post(`${API_URL}/save-note-template`, formData).then(res =>{
           
            Swal.fire({
                icon:"success",
                text:res.data.message
              }).then(function(){ 
                setLoading(false);        
                resetData();
                setErrorList([]);                           
                 getNoteTemplateList();
                   
            });
            }).catch(function(error) {
                setLoading(false);
                setErrorList(error.response.data.validate_err);
                Swal.fire({
                  text:error.response.data.message,
                  icon:"error"
                })
            })
       }

       const resetData = () => {
        setNote_title("");
        setSubject("");
        setNote_message("");
        };
    

    return(
        <div>
    <SideNav/>
    <TopBar/>

    {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}


    <div class="right_panel position contact_section vh-100" id="right_panel" ref={scrollToRef}>

<div class="main_title d-flex align-items-center">
    <h1><i class="bi bi-sticky"></i> Create Note</h1>
</div>

<div class="content_wrapper pt-0">
    <div class="vm_content_box_1">
        <div class="title">
            <h2>Create Template</h2>
        </div>
        <div class="content pt-3">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="note_title">Enter Note Title</label>
                        <input type="text" class="form-control" name="note_title" id="note_title" value={note_title} placeholder="Eg: Pending Payment"
                        onChange={(event) =>{ setNote_title(event.target.value); }}/>
                            <span class="text-danger">{error_list.note_title}</span>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                        <div class="form-group">
                            <label for="subject">Email Subject</label>
                            <input type="text" class="form-control" name="subject" id="subject" placeholder="Enter Email Subject" value={subject}
                            onChange={(event) =>{ setSubject(event.target.value); }}/>
                                 <span class="text-danger">{error_list.subject}</span>
                        </div>
                    </div>

            <div class="col-md-12">
                <div class="form-group">
                    <div class="d-flex justify-content-between">
                        <label for="lotlocation">Generate AI</label>
                        <button class="btn_secondary text-white mb-1 py-0">Generate AI</button>
                    </div>
                    <textarea class="form-control h-100" name="generate_ai" id="generate_ai" cols="" rows="5" placeholder="Ex: Birthday Greeting..."></textarea>
                </div>
            </div>
           

            <div class="col-md-12">
                <div class="form-group">
                    <label for="note_message">Note Message</label>
                    <textarea class="form-control h-100" name="note_message" id="note_message" cols="" value={note_message}  rows="5" placeholder="Ex: Enter Note Message"
                    onChange={(event) =>{ setNote_message(event.target.value); }}></textarea>
                      <span class="text-danger">{error_list.note_message}</span>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
                <div>
                    <button onClick={save} class="btn btn-sm btn_secondary text-white"><i class="bi bi-save me-2"></i> {loading?"Saving":"Save"}</button>
                </div>
            </div>
        </div>
    </div>

    <div class="vm_content_box_1 mt-4">
        <div class="title">
            <h2>Manage Notes</h2>
        </div>

        <div class="content">
                <div class="row pt-3">
                          
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                              
                   
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search Note Templates" type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                          </div>




                          <> <table id="example" class="display dataTable dtr-inline" style={{width:"100%"}}>
                        <thead>
                            <tr>
                                <th>Note Title</th>
                                <th class="text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                        {filteredResult.length == 0 ?
        <table class="display dataTable dtr-inline">
        <tbody>
          <tr>
            <div class="row" style={{margin: "10px -400px 10px 10px"}}>
                       <p  valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                       </div>
          </tr>
        </tbody>
      </table>
      :    null }


{filteredResult.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
                                            <tr key={index}>

        <td>
            <p>{item.note_title}</p>
        </td>
        <td class="d-flex justify-content-end">

                                                    <Link to={`/edit-note-template/${item.id}`} class="btn btn-sm btn_secondary" >
                                                        <i class="bi bi-pencil-square"></i> Edit</Link>

                                                    <button class="btn btn-sm btn_secondary" style={{ background: "red", color: "white", margin: "0px 0px 0px 10px" }}
                                                        onClick={(e) => deleteNoteTemplate(e, item.id)}><i class="bi bi-trash3"></i> Delete</button>
                                                </td>
   
            
        </tr>
        ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>Note Title</th>
                                <th class="text-end">Action</th>
                            </tr>
                        </tfoot>
                    </table><div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                    {filteredResult.length > 0 && `Showing 1 to ${Math.min(perPage, filteredResult.length)} of ${filteredResult.length} entries`}
                </div><div class="col-md-9" style={{ float: "right" }}>

                    <ReactPaginate style={{ float: "right" }}
                        previousLabel={"< Prev"}
                        nextLabel={"Next >"}
                        breakLabel={'...'}
                        pageCount={Math.ceil(filteredResult.length / perPage)}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                        disabledClassName={"disabled"}
                        breakClassName={['page-item']}
                        breakLinkClassName={'page-link'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        activeClassName={['active']} />


                </div></>
                </div>
    </div>
</div>

</div>


        </div>
    )
}

export default SettingNoteTemplate;