import SideNav from "./SideNav";
import TopBar from "./TopBar";
import { useState, useEffect } from "react";
import axios from 'axios';
import {Link, useParams, useNavigate} from "react-router-dom";
import ReactPaginate from 'react-paginate';

function EmailLogs(){

    const API_URL = process.env.REACT_APP_API_URL;
    const LARAVEL_API_URL = process.env.REACT_APP_LARAVEL_API;

    const [isLoading, setIsLoading] = useState(true);
    const [email_logs, setEmail_logs] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [message, setMessage] = useState('');
  

    const filteredResult = email_logs.filter((item) => {
        return item.to.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.created_at.toLowerCase().includes(searchQuery.toLowerCase()) 
        
      });

      const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
      };
      const formatDate = (dateString) => {
        const createdDate = new Date(dateString);
        const options = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        };
    
        const formattedDate = createdDate.toLocaleString('en-US', options);
       // const timeAgo = getTimeAgo(createdDate);
    
       // return `${formattedDate} (${timeAgo})`;
        return `${formattedDate}`;
      };
    
      const getTimeAgo = (date) => {
        const now = new Date();
        const diffInMilliseconds = now - date;
        const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    
        if (diffInMinutes < 60) {
            return `${diffInMinutes} minutes ago`;
        } else if (diffInMinutes < 1440) { // 1440 minutes = 24 hours
            const diffInHours = Math.floor(diffInMinutes / 60);
            return `${diffInHours} hours ago`;
        } else if (diffInMinutes <= 21600) { // 21600 minutes = 15 days
            const diffInDays = Math.floor(diffInMinutes / 1440);
            return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
        } else {
            return ''.replace(/[()]/g, ) ;
        }
    };


      useEffect(() => {
        setIsLoading(true);
        getEmailLogsList();
       
    }, []);


    function getEmailLogsList()
    {
      
      axios.get(`${API_URL}/view-email-logs`).then(res=>{
          if(res.data.status === 200){
              setEmail_logs(res.data.emailLogs); 
             
          }    
          else if(res.data.status === 404){
              setMessage(res.data.message);
              }
              setIsLoading(false);
       });  
     
    }



    
    return (
<div>
<SideNav/>
 <TopBar/>
 {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}




<div class="right_panel position contact_section vh-100" id="right_panel">

<div class="main_title d-flex align-items-center">
    <h1><i class="bi bi-sticky"></i> Email Records</h1>
</div>

<div class="content_wrapper pt-0">
   
    <div class="vm_content_box_1 mt-4">
        <div class="title">
            <h2>Email Record List</h2>
        </div>

        <div class="content">
        <div class="row pt-3">
                          
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                              
                   
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search..." type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                          </div>

                         
                            <><table class="display dataTable dtr-inline" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>To</th>
                                            <th>Subject</th>
                                            <th>Vehicle list Link</th>
                                            <th>Sent at</th>
                                        </tr>
                                    </thead>


                                    <tbody>
                                    {filteredResult.length === 0 ? 
                

                <div class="row" style={{margin: "10px -900px 10px 10px"}}>
                <p id="recordMessage" valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                </div>
                  : null} 

                                        {filteredResult.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
                                            <tr key={index}>

                                                <td>
                                                    <p>{item.to}</p>
                                                </td>

                                                <td>
                                                    <p>{item.subject}</p>
                                                </td>
                                                <td>
    {item.message.match(/(https?:\/\/[^\s]+)/g) && item.message.match(/(https?:\/\/[^\s]+)/g).find(url => url.includes("vehicle-list")) ? (
        <a href={item.message.match(/(https?:\/\/[^\s]+)/g).find(url => url.includes("vehicle-list"))} target="_blank">View Vehicle List</a>
    ) : (
        "No vehicle list available"
    )}
</td>
                                                <td>
                                                    <p>{formatDate(item.created_at)}</p>
                                                </td>    
                                            </tr>


                                        ))}
                                    </tbody>

                                    <tfoot>
                                        <tr>
                                            <th>To</th>
                                            <th>Subject</th>
                                            <th>Vehicle list Link</th>
                                            <th>Sent at</th>
                                        </tr>
                                    </tfoot>
                                </table><div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                        {filteredResult.length > 0 && `Showing 1 to ${Math.min(perPage, filteredResult.length)} of ${filteredResult.length} entries`}
                                    </div><div class="col-md-9" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredResult.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div></>
                          

        </div>
    </div>
</div>

</div>



</div>
    );
}
export default EmailLogs;