import React, {useState,useEffect} from "react";
import './App.css';
import $ from 'jquery';
import ViewInventory from './components/ViewInventory';
import SideNav from './components/SideNav';
import TopBar from "./components/TopBar";
import TopBarInventory from "./components/TopBarInventory";
import {BrowserRouter as Router,Routes, Switch, Route, useNavigate} from 'react-router-dom'
import Inventory from './components/Inventory';
import EditInventory from './components/EditInventory';
import InventorySection2 from "./components/InventorySection2";
import InventorySection3 from "./components/InventorySection3";
import EditInventorySection2 from "./components/EditInventorySection2";
import EditInventorySection3 from "./components/EditInventorySection3";
import SettingInvoicePreset from "./components/SettingInvoicePreset";
import EditSettingInvoicePreset from "./components/EditSettingInvoicePreset";
import SettingImagePreset from "./components/SettingImagePreset";
import Login from "./components/Login";
import SettingImageEditing from "./components/SettingImageEditing";
import InventoryDetails from "./components/InventoryDetails";
import { UserProvider } from './components/UserContext';
import VehicleList from "./components/VehicleList";
import EmailLogs from "./components/EmailLogs";
import ViewCustomers from "./components/ViewCustomers";
import CreateCustomer from "./components/CreateCustomer";
import EditCustomer from "./components/EditCustomer";
import ViewLeads from "./components/ViewLeads";
import LeadsDetails from "./components/LeadsDetails";
import SettingEmailTemplate from "./components/SettingEmailTemplate";
import EditSettingEmailTemplate from "./components/EditSettingEmailTemplate";
import SettingSmsTemplate from "./components/SettingSmsTemplate";
import EditSettingSmsTemplate from "./components/EditSettingSmsTemplate";
import SettingNoteTemplate from "./components/SettingNoteTemplate";
import EditSettingNoteTemplate from "./components/EditSettingNoteTemplate";
import SettingPinTemplate from "./components/SettingPinTemplate";
import EditSettingPinTemplate from "./components/EditSettingPinTemplate";
import SettingMeetingTemplate from "./components/SettingMeetingTemplate";
import ViewDashboard from "./components/ViewDashboard";
import SettingUploadTemplate from "./components/SettingUploadTemplate";
import EditSettingUploadTemplate from "./components/EditSettingUploadTemplate";


function App() {


  useEffect(() => {
   
  }, []);
  
  return (
    <UserProvider>
    <Router >
    <div className="App">
    
      <Routes>


   <Route path='*' element={<Login />} /> 
   <Route path='/top-bar' element={<TopBar />} />
   <Route path='/top-bar-inventory' element={<TopBarInventory />} />

   <Route path='/view-dashboard' element={<ViewDashboard />} />

   <Route path='/view-inventory' element={<ViewInventory />} />
   <Route path='/inventory/:id' element={<Inventory />}/>
    <Route path='/inventory' element={<Inventory/>}/>
    <Route path='/edit-inventory/:id' element={<EditInventory />} />

    <Route path='/inventorySection2/:inventory_section_id' element={<InventorySection2 />} />
    <Route path='/edit-inventorySection2/:id' element={<EditInventorySection2 />} />

    <Route path='/inventorySection3/:inventory_section_id' element={<InventorySection3 />} />
    <Route path='/edit-inventorySection3/:inventory_section_id' element={<EditInventorySection3 />} />

    <Route path='/setting-invoice-preset' element={<SettingInvoicePreset />} />
    <Route path='/edit-invoice-preset/:id' element={<EditSettingInvoicePreset />} />

    <Route path='/setting-image-preset' element={<SettingImagePreset />} />

    <Route path='/setting-image-editing' element={<SettingImageEditing />} />

    <Route path='/setting-email-template' element={<SettingEmailTemplate />} />
    <Route path='/edit-email-template/:id' element={<EditSettingEmailTemplate />} />

    <Route path='/setting-sms-template' element={<SettingSmsTemplate />} />
    <Route path='/edit-sms-template/:id' element={<EditSettingSmsTemplate />} />

    <Route path='/setting-note-template' element={<SettingNoteTemplate />} />
    <Route path='/edit-note-template/:id' element={<EditSettingNoteTemplate />} />

    <Route path='/setting-pin-template' element={<SettingPinTemplate />} />
    <Route path='/edit-pin-template/:id' element={<EditSettingPinTemplate />} />

    <Route path='/setting-meeting-template' element={<SettingMeetingTemplate />} />

    <Route path='/setting-upload-template' element={<SettingUploadTemplate />} />
    <Route path='/edit-upload-template/:id' element={<EditSettingUploadTemplate />} />

    <Route path='/inventory-details/:id' element={<InventoryDetails />} />
    <Route path='/vehicle-list' element={<VehicleList />} />
    <Route path='/email-logs' element={<EmailLogs />} />

    <Route path='/view-customers' element={<ViewCustomers />} />
    <Route path='/create-customer' element={<CreateCustomer />} />
    <Route path='/edit-customer/:id' element={<EditCustomer />} />

    <Route path='/view-leads' element={<ViewLeads />} />
    <Route path='/leads-details/:id' element={<LeadsDetails />} />
    <Route path='/leads-details' element={<LeadsDetails />} />
    
  </Routes>
 

    </div>
    </Router>
    </UserProvider>
  );
}

export default App;
