import SideNav from "./SideNav";
import TopBar from "./TopBar";
import { useState, useEffect, useRef, useContext } from "react";
import Swal from 'sweetalert2';
import axios from 'axios';
import UserContext from './UserContext';
import placeholder from '../img/image_placeholder.png';

export function SettingImagePreset({ storedUsername }) {

    const API_URL = process.env.REACT_APP_API_URL;
    const LARAVEL_API_URL = process.env.REACT_APP_LARAVEL_API;
    const [isLoading, setIsLoading] = useState(false);
    const [image_preset, setImage_preset] = useState([]);
    const [image_preset_id, setImage_preset_id] = useState();
    const [is_dragging_watermark, setIs_dragging_watermark] = useState(false);
    const [is_dragging_header, setIs_dragging_header] = useState(false);
    const [is_dragging_footer, setIs_dragging_footer] = useState(false);
    const [watermarkImage, setWatermarkImage] = useState();
    const [headerImage, setHeaderImage] = useState();
    const [footerImage, setFooterImage] = useState();
    const [files, setFiles] = useState();
    const [watermarkImageURL, setWatermarkImageURL] = useState();
    const [headerImageURL, setHeaderImageURL] = useState();
    const [footerImageURL, setFooterImageURL] = useState();
    const watermarkInputRef = useRef(null);
    const headerInputRef = useRef(null);
    const footerInputRef = useRef(null);
    const [fileState, setFileState] = useState(0);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState();
    const [message, setMessage] = useState('');
    const userContext = useContext(UserContext);
    const [created_by, setCreated_by] = useState();
    const [updated_By, setUpdated_by] = useState();
    const [isWatermarkLoading, setIsWatermarkLoading] = useState(false);
    const [isheaderLoading, setIsHeaderLoading] = useState(false);
    const [isFooterLoading, setIsFooterLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getImagePreset();
        //console.log(userContext.username);
    }, []);



    function getImagePreset() {
        axios.get(`${API_URL}/view-image-preset`).then(res => {
            if (res.data.status === 200) {
                setImage_preset(res.data.imagePreset);
                setImage_preset_id(res.data.imagePreset[0].id);

            } else if (res.data.status === 404) {
                setMessage(res.data.message);
                //setIsLoading(false);
            }
            setIsLoading(false);
        });

    }

    function onDragOverWatermark(event) {
        event.preventDefault();
        setIs_dragging_watermark(true);
        event.dataTransfer.dropEffect = "copy";

    }

    function onDragLeaveWatermark(event) {
        event.preventDefault();
        setIs_dragging_watermark(false);
    }

    function onDropWatermark(event) {
        event.preventDefault();
        setIs_dragging_watermark(false);
        const files = event.dataTransfer.files[0];
        setWatermarkImage(files);
        // console.log(files);
        setWatermarkImageURL(URL.createObjectURL(event.dataTransfer.files[0]));

    }


    function onDragOverHeader(event) {
        event.preventDefault();
        setIs_dragging_header(true);
        event.dataTransfer.dropEffect = "copy";

    }

    function onDragLeaveHeader(event) {
        event.preventDefault();
        setIs_dragging_header(false);
    }

    function onDropHeader(event) {
        event.preventDefault();
        setIs_dragging_header(false);
        const files = event.dataTransfer.files[0];
        setHeaderImage(files);
        // console.log(files);
        setHeaderImageURL(URL.createObjectURL(event.dataTransfer.files[0]));

    }

    function onWatermarkSelect(event) {
        const file = event.target.files[0];
        setWatermarkImage(event.target.files[0]);
        setWatermarkImageURL(URL.createObjectURL(event.target.files[0]));
    }

    function onHeaderSelect(event) {
        const file = event.target.files[0];
        setHeaderImage(event.target.files[0]);
        setHeaderImageURL(URL.createObjectURL(event.target.files[0]));
    }

    function selectWatermarkFile() {
        watermarkInputRef.current.click();

    }

    function selectHeaderFile() {
        headerInputRef.current.click();

    }

    const handleWatermarkImageClick = async (watermarkImageURL) => {

        Swal.fire({
            imageUrl: watermarkImageURL,
            imageAlt: 'Image',
            showCloseButton: true,
            showConfirmButton: false
        });
    };

    const handleHeaderImageClick = async (headerImageURL) => {
        //console.log(headerImageURL);
        Swal.fire({
            imageUrl: headerImageURL,
            imageAlt: 'Image',
            showCloseButton: true,
            showConfirmButton: false
        });
    };


    function onDragOverFooter(event) {
        event.preventDefault();
        setIs_dragging_footer(true);
        event.dataTransfer.dropEffect = "copy";

    }

    function onDragLeaveFooter(event) {
        event.preventDefault();
        setIs_dragging_footer(false);
    }

    function onDropFooter(event) {
        event.preventDefault();
        setIs_dragging_footer(false);
        const files = event.dataTransfer.files[0];
        setFooterImage(files);
        //console.log(files);
        setFooterImageURL(URL.createObjectURL(event.dataTransfer.files[0]));

    }

    function onFooterSelect(event) {
        const file = event.target.files[0];
        setFooterImage(event.target.files[0]);
        setFooterImageURL(URL.createObjectURL(event.target.files[0]));
    }

    function selectFooterFile() {
        footerInputRef.current.click();

    }

    const handleFooterImageClick = async (footerImageURL) => {

        Swal.fire({
            imageUrl: footerImageURL,
            imageAlt: 'Image',
            showCloseButton: true,
            showConfirmButton: false
        });

    };



    function upload() {


        if (headerImage == null && footerImage == null && watermarkImage == null) {
            Swal.fire({
                text: "Please upload image",
                icon: "warning"
            });
        }

        else {
            setLoading(true);
            setFileState(1);

            //console.log(headerImage, footerImage)
            // if (headerImage == null || footerImage == null) {
            //     setLoading(false);
            // }
            const formData = new FormData();

            formData.append('image_preset_id', image_preset_id);
            formData.append('watermarkImage', watermarkImage);
            formData.append('headerImage', headerImage);
            formData.append('footerImage', footerImage);
            formData.append('created_by', userContext.username);
            formData.append('updated_by', userContext.username);


            //console.log(image_preset_id)
            axios.post(`${API_URL}/save-image-preset`, formData).then(res => {
                //  setSuccess('Images uploaded successfully!!');
                Swal.fire({
                    icon: "success",
                    text: res.data.message
                }).then(function () {
                    setLoading(false);
                    resetData();
                    getImagePreset();
                });



            }).catch(function (error) {
                // if(error.response.status===422){         
                //    setErrorList(error.response.data.validate_err)
                // }
                // else{
                Swal.fire({
                    text: error.response.data.message,
                    icon: "error"
                });
                // }
            });
        }



    }

    const resetData = () => {
        setWatermarkImage(null);
        setHeaderImage(null);
        setFooterImage(null);
        setFileState(0);
    };

    const handleWatermarkImageLoad = () => {
        // setTimeout(() => {
        //     setIsWatermarkLoading(true);
        //   }, 5000); 
        setIsWatermarkLoading(true);
    };

    const handleHeaderImageLoad = () => {
        // setTimeout(() => {
        //     setIsHeaderLoading(true);
        //    }, 1000); 
        setIsHeaderLoading(true);
    };

    const handleFooterImageLoad = () => {
        // setTimeout(() => {
        //     setIsFooterLoading(true);
        //   }, 5000); 
        setIsFooterLoading(true);
    };


    return (
        <div>
            <SideNav />
            <TopBar />

            {isLoading ?
                <div className="loader-container" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed", top: "0", left: "0", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: "9999" }}>
                    <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                        </div>
                    </div>
                </div> : null}


            <div class="right_panel position contact_section vh-100" id="right_panel">

                <div class="main_title d-flex align-items-center">
                    <h1><i class="bi bi-images"></i> Create Image Preset</h1>
                </div>

                <div class="content_wrapper pt-0">
                    <div class="vm_content_box_1">
                        <div class="title">
                            <h2>Upload Images</h2>
                        </div>
                        <div class="row">

                            <div class="col-4">
                                <div class="content pt-3">
                                    <div onDragOver={onDragOverWatermark} onDragLeave={onDragLeaveWatermark} onDrop={onDropWatermark} class="border d-flex justify-content-center align-items-center h-100 highlightbox_1 pb-3 pt-3">
                                        <div>
                                            <div class="d-flex justify-content-center">
                                                <i class="bi bi-upload display-6"></i>
                                            </div>
                                            <h4 class="text-center">Upload watermark Image</h4>
                                            {is_dragging_watermark
                                                ? (<span class="text-center" className="select">Drop images here</span>)
                                                : (<> Drag & Drop image here or {" "}
                                                    <span style={{ color: "deepskyblue", textDecorationLine: "underline" }} class="text-center" className="select" role="button" onClick={selectWatermarkFile}>browse</span>
                                                </>
                                                )}
                                            <input type="file" ref={watermarkInputRef} onChange={onWatermarkSelect} class="d-none"></input>
                                        </div>
                                    </div>


                                    <div className="container" style={{ maxHeight: "200px", overflowY: "auto", display: "flex" }}>
                                        {watermarkImage &&
                                            <div style={{ position: "relative", marginRight: "5px", marginBottom: "8px", height: "100%", width: "100%" }} className="image">
                                                <img style={{ width: "300px", height: "60px", borderRadius: "5px" }} src={watermarkImageURL} alt={watermarkImageURL} />
                                            </div>}
                                    </div>
                                    {/* {fileState == 1 && watermarkImage == null ? <span style={{ padding: "5px" }} class="text-danger">{'Please upload Watermark Image'}</span> : null} */}

                                </div>

                            </div>


                            <div class="col-4">
                                <div class="content pt-3">
                                    <div onDragOver={onDragOverHeader} onDragLeave={onDragLeaveHeader} onDrop={onDropHeader} class="border d-flex justify-content-center align-items-center h-100 highlightbox_1 pb-3 pt-3">
                                        <div>
                                            <div class="d-flex justify-content-center">
                                                <i class="bi bi-upload display-6"></i>
                                            </div>
                                            <h4 class="text-center">Upload Header Image</h4>
                                            {is_dragging_header
                                                ? (<span class="text-center" className="select">Drop images here</span>)
                                                : (<> Drag & Drop image here or {" "}
                                                    <span style={{ color: "deepskyblue", textDecorationLine: "underline" }} class="text-center" className="select" role="button" onClick={selectHeaderFile}>browse</span>
                                                </>
                                                )}
                                            <input type="file" ref={headerInputRef} onChange={onHeaderSelect} class="d-none"></input>
                                        </div>
                                    </div>


                                    <div className="container" style={{ maxHeight: "200px", overflowY: "auto", display: "flex" }}>
                                        {headerImage &&
                                            <div style={{ position: "relative", marginRight: "5px", marginBottom: "8px", height: "100%", width: "100%" }} className="image">
                                                <img style={{ width: "300px", height: "60px", borderRadius: "5px" }} src={headerImageURL} alt={headerImageURL} />
                                            </div>}
                                    </div>
                                    {/* {fileState == 1 && headerImage == null ? <span style={{ padding: "5px" }} class="text-danger">{'Please upload Header Image'}</span> : null} */}

                                </div>

                            </div>



                            <div class="col-4">
                                <div class="content pt-3">
                                    <div onDragOver={onDragOverFooter} onDragLeave={onDragLeaveFooter} onDrop={onDropFooter} class="border d-flex justify-content-center align-items-center h-100 highlightbox_1 pb-3 pt-3">
                                        <div>
                                            <div class="d-flex justify-content-center">
                                                <i class="bi bi-upload display-6"></i>
                                            </div>
                                            <h4 class="text-center">Upload Footer Image</h4>
                                            {is_dragging_footer
                                                ? (<span class="text-center" className="select">Drop images here</span>)
                                                : (<> Drag & Drop image here or {" "}
                                                    <span style={{ color: "deepskyblue", textDecorationLine: "underline" }} class="text-center" className="select" role="button" onClick={selectFooterFile}>browse</span>
                                                </>
                                                )}
                                            <input type="file" ref={footerInputRef} onChange={onFooterSelect} class="d-none"></input>
                                        </div>
                                    </div>


                                    <div className="container" style={{ maxHeight: "200px", overflowY: "auto", display: "flex" }}>
                                        {footerImage &&
                                            <div style={{ position: "relative", marginRight: "5px", marginBottom: "8px", height: "100%", width: "100%" }} className="image">
                                                <img style={{ width: "300px", height: "60px", borderRadius: "5px" }} src={footerImageURL} alt={footerImageURL} />
                                            </div>}
                                    </div>
                                    {/* {fileState == 1 && footerImage == null ? <span style={{ padding: "5px" }} class="text-danger">{'Please upload Footer Image'}</span> : null} */}

                                </div>
                            </div>




                        </div>

                        <button class="border px-2 py-2 d-inline vm_bg_secondary text-white vm_cursor_pointer" style={{ margin: "10px 10px 10px 20px" }} onClick={upload}>{loading ? 'Uploading' : 'Upload'} </button>


                    </div>



                    <div class="vm_content_box_1 mt-4">
                        <div class="title">
                            <h2>   <i class="bi bi-images"></i> Images</h2>
                        </div>


                        <div class="content">







                            <table class="display dataTable dtr-inline" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th>Watermark Image</th>
                                        <th>Header Image</th>
                                        <th>Footer Image</th>
                                        <th>Created by</th>
                                        <th>Updated by</th>

                                    </tr>
                                </thead>


                                <tbody>

                                    {image_preset.length == 0 ?
                                        <table class="display dataTable dtr-inline">
                                            <tbody>
                                                <tr>
                                                    <div class="row" style={{ margin: "10px -900px 10px 10px" }}>
                                                        <p valign="top" class="odd" style={{ textAlign: "center" }}>No records found</p>
                                                    </div>
                                                </tr>
                                            </tbody>
                                        </table>
                                        : null}



                                    {image_preset.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                {/* {isWatermarkLoading?
                       <>{item.filepath_watermark ? <img  onClick={() => handleWatermarkImageClick(watermarkImageURL)}  loading="lazy" style={{ width: "300px", height: "60px", borderRadius: "5px" }} class="inventory_list_img" src={`${LARAVEL_API_URL}${item.filepath_watermark}`} alt="Benz E200" preload /> : "No Image found"}</>
                       :<img onLoad={handleWatermarkImageLoad} src={placeholder} style={{width: "160px", height: "60px"}} alt="Loading..." preload />
                  } */}

                                                {!isWatermarkLoading && item.filepath_watermark && <img style={{ width: "160px", height: "60px" }} src={placeholder} />}
                                                {item.filepath_watermark ? (
                                                    <img style={!isWatermarkLoading ? { display: 'none' } : { width: "300px", height: "60px", borderRadius: "5px" }} onClick={() => handleWatermarkImageClick(watermarkImageURL || `${LARAVEL_API_URL}${item.filepath_watermark}`)} src={`${LARAVEL_API_URL}${item.filepath_watermark}`} onLoad={handleWatermarkImageLoad} />
                                                ) : (
                                                    // {/* <img style={{ width: "180px", height: "120px" }} src={placeholderImage} /> */}
                                                    <div style={{
                                                        position: 'relative',
                                                        width: '160px',
                                                        height: '60px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}>
                                                        <img style={{ width: "160px", height: "60px"}} src={placeholder} alt="Placeholder" />
                                                        <span style={{
                                                            position: 'absolute',
                                                            color: 'black',
                                                            padding: '5px 10px',
                                                            borderRadius: '3px',
                                                            marginBottom:"-35px",
                                                            top: "50%", 
                                                            left: "50%", 
                                                            transform: "translate(-50%, -50%)", 
                                                            backgroundColor: "rgba(255, 255, 255, 0.7)",
                                                        }}>No image available
                                                        </span>
                                                    </div>
                                                )}
                                            </td>
                                            <td>

                                                {/* {isheaderLoading?
                       <>  {item.filepath_header ? <img  onClick={() => handleHeaderImageClick(headerImageURL)}  loading="lazy" style={{ width: "300px", height: "60px", borderRadius: "5px" }} class="inventory_list_img" src={`${LARAVEL_API_URL}${item.filepath_header}`} alt="Benz E200" preload/> : "No Image found"}</>
                       :<img onLoad={handleHeaderImageLoad} src={placeholder} style={{width: "160px", height: "60px"}} alt="Loading..."  preload/>
                  } */}

                                                {!isheaderLoading && item.filepath_header && <img style={{ width: "160px", height: "60px" }} src={placeholder} />}
                                                {item.filepath_header ? (
                                                    <img style={!isheaderLoading ? { display: 'none' } : { width: "300px", height: "60px", borderRadius: "5px" }} onClick={() => handleHeaderImageClick(headerImageURL || `${LARAVEL_API_URL}${item.filepath_header}`)} src={`${LARAVEL_API_URL}${item.filepath_header}`} onLoad={handleHeaderImageLoad} />
                                                ) : (
                                                    // {/* <img style={{ width: "180px", height: "120px" }} src={placeholderImage} /> */}
                                                    <div style={{
                                                        position: 'relative',
                                                        width: '160px',
                                                        height: '60px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}>
                                                        <img style={{ width: "160px", height: "60px"}} src={placeholder} alt="Placeholder" />
                                                        <span style={{
                                                            position: 'absolute',
                                                            color: 'black',
                                                            padding: '5px 10px',
                                                            borderRadius: '3px',
                                                            top: "50%", 
                                                            left: "50%", 
                                                            transform: "translate(-50%, -50%)", 
                                                            marginBottom:"-35px", 
                                                            backgroundColor: "rgba(255, 255, 255, 0.7)",
                                                        }}>No image available
                                                        </span>
                                                    </div>
                                                )}
                                                {/* {isheaderLoading ? (
                      item.filepath_header ? (
                        <img
                          onClick={() => handleHeaderImageClick(headerImageURL)}
                          loading="lazy"
                          style={{ width: "300px", height: "60px", borderRadius: "5px" }}
                          className="inventory_list_img"
                          src={`${LARAVEL_API_URL}${item.filepath_header}`}
                          alt="Benz E200"
                          onLoad={handleHeaderImageLoad}
                        //   onError={handleHeaderImageError}
                        />
                      ) : (
                        <div>No Image found</div>
                      )
                    ) : (
                      <img
                        src={placeholder}
                        style={{ width: "160px", height: "60px" }}
                        alt="Loading..."
                      />
                    )} */}


                                            </td>

                                            <td>
                                                {/* {isFooterLoading?
                       <>    {item.filepath_footer ? <img  onClick={() => handleFooterImageClick(footerImageURL)}  loading="lazy" style={{ width: "300px", height: "60px", borderRadius: "5px" }} class="inventory_list_img" src={`${LARAVEL_API_URL}${item.filepath_footer}`} alt="Benz E200" preload/> : "No Image found"}</>
                       :<img onLoad={handleFooterImageLoad} src={placeholder} style={{width: "160px", height: "60px"}} alt="Loading..."  preload/>
                  } */}
                                                {!isFooterLoading && item.filepath_footer && <img style={{ width: "160px", height: "60px" }} src={placeholder} />}
                                                {item.filepath_footer ? (
                                                    <img style={!isFooterLoading ? { display: 'none' } : { width: "300px", height: "60px", borderRadius: "5px" }} onClick={() => handleFooterImageClick(footerImageURL || `${LARAVEL_API_URL}${item.filepath_footer}`)} src={`${LARAVEL_API_URL}${item.filepath_footer}`} onLoad={handleFooterImageLoad} />
                                                ) : (
                                                    // {/* <img style={{ width: "180px", height: "120px" }} src={placeholderImage} /> */}
                                                   <div style={{
    position: 'relative',
    width: '160px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}}>
    <img style={{ width: "160px", height: "60px"}} src={placeholder} alt="Placeholder" />
    <span style={{
        position: 'absolute',
        color: 'black',
        padding: '5px 10px',
        borderRadius: '3px',
        marginBottom:"-35px",
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        top: "50%", 
        left: "50%", 
        transform: "translate(-50%, -50%)", 
    }}>
       No image available
    </span>
</div>
                                                )}

                                            </td>

                                            <td style={{ verticalAlign: 'middle' }}>
                                                <p>{item.created_by}</p>
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                <p>{item.updated_by}</p>
                                            </td>

                                        </tr>


                                    ))}
                                </tbody>

                                {/* <tfoot>
<tr>
<th>Header Image</th>
<th>Footer Image</th>
<th>created By</th>

</tr>
</tfoot>  */}
                                <tfoot>
                                    <tr>
                                        <th>Watermark Image</th>
                                        <th>Header Image</th>
                                        <th>Footer Image</th>
                                        <th>Created by</th>
                                        <th>Updated by</th>

                                    </tr>
                                </tfoot>

                            </table>



                        </div>
                    </div>

                </div>
            </div>




        </div>
    );
}


export default SettingImagePreset;