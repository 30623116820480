import { useState, useEffect, useRef } from "react";
import SideNav from "./SideNav";
import TopBar from "./TopBar";
import axios from 'axios';
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
import {Link, useParams, useNavigate} from "react-router-dom";


function EditSettingNoteTemplate(){

    const API_URL = process.env.REACT_APP_API_URL;
    const LARAVEL_API_URL = process.env.REACT_APP_LARAVEL_API;
    const { id } = useParams();
    const navigate = useNavigate();
    const [note_template, setNote_template] = useState([]);
    const [note_template_by_id, setNote_template_by_id] = useState([]);
    const [error_list, setErrorList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const scrollToRef = useRef(null);

    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [loading, setLoading] = useState(false);

    const filteredResult = note_template.filter((item) => {
        return item.note_title.toLowerCase().includes(searchQuery.toLowerCase()) 
      });
      


      
    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
      };
    
      const handleInput = (e) =>{
          setNote_template_by_id({...note_template_by_id, [e.target.name] : e.target.value})
        }

    useEffect(() => {
        
       
        setIsLoading(true);
         axios.get(`${API_URL}/edit-note-template/`+id, ).then(res=>{
           
            if(res.data.status === 200){
                setNote_template_by_id(res.data.noteTemplate);        
            }
            else if(res.data.status === 404){
                
             // console.log(res.data.error)
            }      
            getNoteTemplateList(); 
          
         });
         

        }, []);


       function getNoteTemplateList()
      {
        
        axios.get(`${API_URL}/view-note-template`).then(res=>{
            if(res.data.status === 200){
                setNote_template(res.data.noteTemplate);    
                scrollToRef.current.scrollIntoView({ behavior: "smooth" });            
            }     
            else if(res.data.status === 404){
              setNote_template([]);
              //  setMessage(res.data.message);     
                }
                setIsLoading(false);
         });  
       
      }

          async function update(event)
          {
           event.preventDefault(); 
           setLoading(true);

          const formData = new FormData();
      formData.append('note_title', note_template_by_id.note_title)
      formData.append('subject', note_template_by_id.subject)
      formData.append('note_message', note_template_by_id.note_message)    
   
         
     await axios.post(`${API_URL}/update-note-template/` +id ,formData).then(res =>{  
    
        Swal.fire({
                  icon:"success",
                  text:res.data.message
                }).then(function(){
                    setErrorList([]);
                    navigate('/setting-note-template');
              });
              }).catch(function(error) {        
                 setErrorList(error.response.data.validate_err);
              
                  Swal.fire({
                 
                    text:error.response.data.message,
                    icon:"error"
                  })
              })
              setLoading(false);
           
              }

              const deleteNoteTemplate = (e,id) =>{
                e.preventDefault();
                var buttonClicked = window.confirm("Are you sure you want to delete Note template ?");
                if(buttonClicked == true){
                    axios.delete(`${API_URL}/delete-note-template/`+id).then(res => {
                     
                  Swal.fire({
                          icon:"success",
                          text:res.data.message
                        }).then(function(){
                      });
        
                       getNoteTemplateList();
                    })
                }
                else
                {
                    getNoteTemplateList();
                }
               
               }

     

     function editNoteTemplate(id){
        setIsLoading(true);
        setErrorList([]);
        axios.get(`${API_URL}/edit-note-template/`+id, ).then(res=>{
           
        if(res.data.status === 200){
            setNote_template_by_id(res.data.noteTemplate);
                scrollToRef.current.scrollIntoView({ behavior: "smooth" });
                
        }
        else if(res.data.status === 404){
       Swal("Error",res.data.error,"error");
          //console.log(res.data.error)
        }
   
      
     });
  
     getNoteTemplateList();
    
  
    }



    
    return (
        <div>
<SideNav/>
<TopBar/>
{isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}

<div class="right_panel position contact_section vh-100" id="right_panel" ref={scrollToRef}>

        <div class="main_title d-flex aling-items-center justify-content-start">
            <i class="bi bi-sticky "></i>
            <h1>Edit Note Template</h1>
        </div>

        <div class="content_wrapper pt-0">
            <div class="vm_content_box_1">
                <div class="title">
                    <h2>Edit Template</h2>
                </div>
                <div class="content pt-3">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="template_name">Note Title</label>
                                <input type="text" class="form-control" name="note_title"  id="note_title"  value={note_template_by_id.note_title} placeholder="Enter Note Title"
                                   onChange={handleInput }/>
                                      <span class="text-danger">{error_list.note_title}</span>
                            </div>
                        </div>
                    
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="subject">Email Subject</label>
                            <input type="text" class="form-control" name="subject" id="subject" placeholder="Enter Email Subject" value={note_template_by_id.subject}
                            onChange={handleInput }/>
                                 <span class="text-danger">{error_list.subject}</span>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group">
                            <div class="d-flex justify-content-between">
                                <label for="lotlocation">Generate AI</label>
                                <button class="btn_secondary text-white mb-1 py-0">Generate AI</button>
                            </div>
                            <textarea class="form-control h-100" name="generate_ai" id="generate_ai" cols="" rows="5" placeholder="Ex: Birthday Greeting..."></textarea>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="lotlocation">Note Message</label>
                            <textarea class="form-control" name="note_message" id="note_message" cols="" rows="5" value={note_template_by_id.note_message} placeholder="Note Message"
                           onChange={handleInput } />
                                 <span class="text-danger">{error_list.note_message}</span>
                        </div>
                    </div>
                    <div class="d-md-flex align-items-center justify-content-between">
                      
                      
                        <div>

                            <button onClick={update} class="btn btn-sm btn_secondary text-white"><i class="bi bi-save me-2"></i> {loading?"Updating":"Update"}</button>
                        </div>                     
                    </div>

                   

                                        
                </div>
            </div>

            <div class="vm_content_box_1 mt-4">
                <div class="title">
                    <h2>Note Templates</h2>
                </div>

                <div class="content">
                <div class="row pt-3">
                          
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                              
                   
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search Note Templates" type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                          </div>




                          <> <table id="example" class="display dataTable dtr-inline" style={{width:"100%"}}>
                        <thead>
                            <tr>
                                <th>Note Title</th>
                                <th class="text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                        {filteredResult.length == 0 ?
        <table class="display dataTable dtr-inline">
        <tbody>
          <tr>
            <div class="row" style={{margin: "10px -400px 10px 10px"}}>
                       <p  valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                       </div>
          </tr>
        </tbody>
      </table>
      :    null }


{filteredResult.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
                                            <tr key={index}>

        <td>
            <p>{item.note_title}</p>
        </td>
        <td class="d-flex justify-content-end">

        <Link to={`/edit-note-template/${item.id}`} onClick={(e) => editNoteTemplate(item.id)} class="btn btn-sm btn_secondary" >
                                                        <i class="bi bi-pencil-square"></i> Edit</Link>
                            <button class="btn btn-sm btn_secondary" style={{ background: "red", color: "white", margin: "0px 0px 0px 10px" }}
                                                        onClick={(e) => deleteNoteTemplate(e, item.id)}><i class="bi bi-trash3"></i> Delete</button>
                                                </td>
   
            
        </tr>
        ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>Note Title</th>
                                <th class="text-end">Action</th>
                            </tr>
                        </tfoot>
                    </table><div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                    {filteredResult.length > 0 && `Showing 1 to ${Math.min(perPage, filteredResult.length)} of ${filteredResult.length} entries`}
                </div><div class="col-md-9" style={{ float: "right" }}>

                    <ReactPaginate style={{ float: "right" }}
                        previousLabel={"< Prev"}
                        nextLabel={"Next >"}
                        breakLabel={'...'}
                        pageCount={Math.ceil(filteredResult.length / perPage)}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                        disabledClassName={"disabled"}
                        breakClassName={['page-item']}
                        breakLinkClassName={'page-link'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        activeClassName={['active']} />


                </div></>
                </div>
            </div>

        </div>
        

</div>

        </div>
    )
}
export default EditSettingNoteTemplate;