import SideNav from "./SideNav";
import TopBar from "./TopBar";
import { useState, useEffect, useRef, useContext } from "react";
import Swal from 'sweetalert2';
import axios from 'axios';
import {Link, useParams, useNavigate} from "react-router-dom";
import placeholder from '../img/image_placeholder.png'

function SettingImageEditing(){

    const [image, setImage] = useState();
    const [imageURL, setImageURL] = useState();
    const [editedImage, setEditedImage] = useState();
    const [loading, setLoading] = useState(false);
    const API_URL = process.env.REACT_APP_API_URL;
    const [fileState, setFileState] = useState(0);
    const [is_dragging, setIs_dragging] = useState(false);
    const inputRef = useRef(null);
    const [compressedImageUrl, setCompressedImageUrl] = useState(null);
    const [downloadLink, setDownloadLink] = useState(null);
    const [isImageLoading, setIsImageLoading] = useState(false);
 

    function onSelect(event){
        const file = event.target.files[0];
        if (file) {
        setImage(event.target.files[0]);
        setImageURL(URL.createObjectURL(event.target.files[0]));
        }

       }

       function selectFile() {
        inputRef.current.click();

    }

    function onDragOver(event) {
        event.preventDefault();
        setIs_dragging(true);
        event.dataTransfer.dropEffect = "copy";

    }

    function onDragLeave(event) {
        event.preventDefault();
        setIs_dragging(false);
    }

    function onDrop(event) {
        event.preventDefault();
        setIs_dragging(false);
        const files = event.dataTransfer.files[0];
        setImage(files);
        setImageURL(URL.createObjectURL(event.dataTransfer.files[0]));

    }

    const handleImageClick = async (imageURL) => {
        Swal.fire({
            imageUrl: imageURL,
            imageAlt: 'Image',
            showCloseButton: true,
            showConfirmButton: false
        });
    };

     function upload() {

        setLoading(true);
        setFileState(1);

        const formData = new FormData()
        formData.append('image', image);
      
         axios.post(`${API_URL}/save-image-editing`, formData).then(res => {
            setEditedImage(`${res.data.croppedImageUrl}?t=${new Date().getTime()}`);


            const imageURL = res.data.croppedImageUrl;     
            //const filename = new URL(imageURL).pathname.split('/').pop();
            const filename = 'edited_image.jpg'
            convertImageFromURLToFile(imageURL,filename);

            setDownloadLink();

    
           // console.log(res.data);
            Swal.fire({
                icon: "success",
                text: res.data.message
            }).then(function () {
               // resetData();
            });

            setLoading(false);

        }).catch(function (error) {
            // if(error.response.status===422){         
            //    setErrorList(error.response.data.validate_err)
            // }
            // else{
              Swal.fire({
               text:error.response.data.message,
               icon:"error"
             })
             setLoading(false);
            // }
        })


        async function convertImageFromURLToFile(imageURL, outputFileName) {
            try {
                const response = await fetch(imageURL);
    const blob = await response.blob();
    const file = new File([blob], outputFileName, { type: blob.type });

    // Example: Use the file as needed
    // console.log('Converted file:', file);
    
    setDownloadLink(URL.createObjectURL(file));
          
            } catch (error) {
              console.error('Error converting main image:', error);
            }
          }

       

    }
    const handleDownload  = async () => {
        const link = document.createElement('a');
        link.href = downloadLink;
        link.download = 'cropped_image.jpg';
        link.click();
    };

    const handleImageLoad = () => {
       
        // setTimeout(() => {
        //     setIsImageLoading(true);
        //   }, 5000); 
      // setIsImageLoading(true);
    };
    
return(
<div>
    <TopBar/>
    <SideNav/>
    <div class="right_panel position contact_section vh-100" id="right_panel">

<div class="main_title d-flex align-items-center">
    <h1><i class="bi bi-sticky"></i> Edit Image </h1>
</div>

<div class="content_wrapper pt-0">
    <div class="vm_content_box_1">
        <div class="title">
            <h2>Upload Images</h2>
        </div>         
        <div class="row">
           
                            <div class="col">
                                <div class="content pt-3">
                                    <div onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop} class="border d-flex justify-content-center align-items-center h-100 highlightbox_1 pb-3 pt-3">
                                        <div >
                                            <div class="d-flex justify-content-center">
                                                <i class="bi bi-upload display-6"></i>
                                            </div>
                                            <h4 class="text-center">Upload Image</h4>
                                            {is_dragging
                                                ? (<span class="text-center" className="select">Drop image here</span>)
                                                : (<> Drag & Drop image here or {" "}
                                                    <span style={{ color: "deepskyblue", textDecorationLine: "underline" }} class="text-center" className="select" role="button" onClick={selectFile}>browse</span>
                                                </>
                                                )}
                                            <input type="file" ref={inputRef} onChange={onSelect} class="d-none" />
                                        </div>
                                    </div>


                                    <div style={{ maxHeight: "200px", overflowY: "auto", display: "flex" }} >
                                        {image &&
                                            <div style={{ position: "relative", marginRight: "5px", marginBottom: "8px", height: "75px", width: "75px" }} className="image" >
                                                <img  style={{ width: "100%", height: "100%", borderRadius: "5px" }} src={imageURL} alt={imageURL} />
                                            </div>
                                        }
                                    </div>
                                    {fileState == 1 && image == null ? <span style={{ padding: "5px" }} class="text-danger">{'Please upload Image'}</span> : null}<br/>
                                    <button class="border px-2 py-2 d-inline vm_bg_secondary text-white vm_cursor_pointer" style={{margin: "10px 10px 10px 0px"}} onClick={upload} >{loading ? 'Uploading' :  'Upload'} </button>       
                                </div>
                               
                            
                           

                            </div>

                            <div class="col">
                            <div  className="container">
                       
                            {editedImage && 
                            <>  <div style={{padding:"0px"}} class="title"><h2>Your Image has been edited!!</h2> </div> <br/>
                            <img loading="lazy" onClick={() => handleImageClick(editedImage)} style={{ width: "180px", height: "135px", borderRadius: "5px" }} src={editedImage} alt="Edited Image" /><br/>
                                {/* {!isImageLoading && <img src={placeholder}style={{width: "180px", height: "135px"}} alt="Loader" />}
    <img
     src={editedImage}
      alt="Desired Image" loading="lazy" onClick={() => handleImageClick(editedImage)} width="180px"
      style={{ display: isImageLoading ? 'block' : 'none'}}
      onLoad={handleImageLoad}
    /> */}
                               {/* {isImageLoading ? 
                               <><img loading="lazy" onClick={() => handleImageClick(editedImage)} style={{ width: "180px", height: "135px", borderRadius: "5px" }} src={editedImage} alt="Edited Image" /><br/></>
                               :<img onLoad={handleImageLoad} src={placeholder} style={{width: "180px", height: "135px"}} alt="Loading..." /> }    */}
                           <div><button onClick={handleDownload} style={{margin: "10px 0px 0px 0px"}} class="border px-2 py-2 d-inline vm_bg_secondary text-white vm_cursor_pointer"> <i class="bi bi-download display"></i> Download</button></div>
                               </>}
                       
                            </div>
                          
                    
                            </div>


       

            </div>     
            
          
            
        
    </div>   


    



</div>
</div>
</div>
);
}
export default SettingImageEditing;
