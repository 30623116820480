
import SideNav from "./SideNav";
import TopBar from "./TopBar";
import React, { useState,  useEffect, useRef } from 'react';
import axios from 'axios';
import { Link} from "react-router-dom";
import placeholderImage from '../img/image_placeholder.png'
import noImagePlaceholder from '../img/no_image_placeholder.png'

function ViewLeads(){

    const API_URL = process.env.REACT_APP_API_URL;
    const LARAVEL_API_URL = process.env.REACT_APP_LARAVEL_API;
   
    const [isLoading, setIsLoading] = useState(false);
    const [customer, setCustomer] = useState([]);
    const [isImageLoading, setIsImageLoading] = useState(true);

    useEffect(() => { 
        setIsLoading(true);
          getCustomerList();
}, []);


const handleImageLoad = () => {
    setIsImageLoading(false);
};



async function getCustomerList(){
 
    axios.get(`${API_URL}/view-customer`).then(res=>{
        if(res.data.status === 200){
          const customer = res.data.customer;
            setCustomer(res.data.customer);  
            //console.log(res.data.customer);
        }
        else if(res.data.status === 404){
            //setMessage(res.data.message);
           // console.log(res.data.status);
            }           
        setIsLoading(false);
     });
       
    }
    return(
<div>
<SideNav/>
      <TopBar/>
      {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}


      <div class="right_panel position contact_section vh-100" id="right_panel">
        <div class="contact_section_wrapper">
            <div class="d-lg-flex">
            
                <a id="contact_inbox" class="a_id_contact_inbox position-relative"></a>
                <div class="message_section bg-white">
    <div class="main_title bg-white border-bottom d-flex justify-content-between">
        <h1><i class="bi bi-person-rolodex"></i>Leads</h1>
            <button class="btn btn-sm btn_secondary">
            <Link style={{color:"white"}} to={'/create-customer'}>Create New Leads </Link></button>
    </div>
   
    <div class="p-3 message_item_wrapper">

    {customer.length === 0 ? 
                

                <div class="row">
                <p id="recordMessage" valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                </div>
                  : null} 

    {customer.map((item, index) => (
            <div class="item d-flex w-100">
                {/* <div class="profile_photo vm_bg_center vm_bg_cover vm_bg_norepeat me-2" style={{ background: `url('${LARAVEL_API_URL}/${item.customer_photo_path}')` }}></div> */}
                {(isImageLoading ) ? <img class="profile_photo vm_bg_center vm_bg_cover vm_bg_norepeat me-2" onLoad={handleImageLoad} src={placeholderImage} />
                                 
                                 : <img class="profile_photo vm_bg_center vm_bg_cover vm_bg_norepeat me-2"  src={item.customer_photo_path ? `${LARAVEL_API_URL}/${item.customer_photo_path}` : noImagePlaceholder} />

}


                <div class="w-100">
                    <div class="d-flex justify-content-between">
                    <Link to={`/leads-details/${item.id}`}> 
                            <h5 class="vm_font_bold me-3 message_user_name">{item.first_name} {item.last_name}</h5>
                       </Link>
                        <div class="d-flex align-items-center">
                            <select class="border-0 bg-light message_user_status" value={item.user_type}>
                                <option value="Customer">Customer</option>
                                <option value="Leads">Leads</option>
                            </select>
                           {item.user_type == "Customer"?<div class="bg-success status_color mx-2"></div>: <div class="bg-warning status_color mx-2"></div>} 
                           
                           <Link to={`/leads-details/${item.id}`}> 
                                <i class="bi bi-arrow-right-square"></i>
                                </Link>
                        </div>
                    </div>
                    <Link to={`/leads-details/${item.id}`}> 
                        <div class="d-flex recent_update">
                            <p>Form Submitted</p>
                            <p class="mx-2">-</p>
                            <p>15.02.2023</p>
                            <p class="mx-2">-</p>
                            <p>03:35</p>
                        </div>
                    </Link>
                </div>
            </div>
        
        ))}
    </div>
</div>
               
                <a id="contact_profile" class="a_id_contact_profile position-relative"></a>
                <div class="border border-top-0 border-bottom-0 user_panel bg-white">

                </div>

                <a id="contact_history" class="a_id_contact_history position-relative"></a>
                <div class="activity bg-white">

                </div>
            </div>
        </div>
    </div>

</div>



    );
}
export default ViewLeads;