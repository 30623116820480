import { useState, useEffect } from "react";
import SideNav from "./SideNav";
import TopBar from "./TopBar";
import { OpenAI } from 'openai';
import Swal from 'sweetalert2';
import axios from 'axios';
import {Link, useParams, useNavigate} from "react-router-dom";
import { event } from "jquery";


function EditInventory(){
 
    const API_URL = process.env.REACT_APP_API_URL;
    const [inventory, setInventory] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [global_inventory, setGlobal_inventory] = useState("");
    const [decodeFlag, setDecodeFlag] = useState(false);
    
    const [checkedoptions, setCheckedoptions] = useState([]);
    const [checkedoptionsai, setCheckedoptionsai] = useState([]);
    const [checkedoptionstable, setCheckedoptionstable] = useState([]);
    const [error_list, setErrorList] = useState([]);
    const [vin, setVin] = useState("");
    
    const [tablecheckbox, setTablecheckbox] = useState("");
    const isCheckedoptions = (item) =>checkedoptions.includes(item) ? "checked-item" : "not-checked-item";
    const [changeEventValue, setChangeEventValue] = useState(false);
    //const isCheckedoptionsaiDefault = (item) =>checkedoptionsai =  "checked-item";
    const isCheckedoptionsai = (item) =>checkedoptionsai.includes(item) ? "checked-item" : "not-checked-item";
    
    const [defaultvalueai, setDefaultValueai] = useState([]);
    const checklistoptionstax = ["Tax Exempt", "Tax Override"];
    const [checkedoptionstax, setCheckedoptionstax] = useState([]);
    const isCheckedoptionstax = (item) =>checkedoptionstax.includes(item) ? "checked-item" : "not-checked-item";
    const isCheckedoptionstable = (item) =>checkedoptionstable.includes(item) ? "checked-item" : "not-checked-item";
    const [checklistoptionsai, setChecklistoptionsai] = useState([]);
    const [Vindata,setData]= useState([]);
    const [error,setError]= useState();
  const [brand, setBrand] = useState("");
  const [model_name, setModel_name] = useState("");
  const [year, setYear] = useState("");
  const [engine_type, setEngine_type] = useState("");
  const [drive_type, setDrive_type] = useState("");
  const [transmission, setTransmission] = useState("");
  const [door, setDoor] = useState("");
  const [trim, setTrim] = useState("");
  const [vehicle_type, setVehicle_type] = useState("");
  const [body_type, setBody_type] = useState("");
  const [cylinders, setCylinders] = useState("");
  const [fuel_type, setFuel_type] = useState("");
  const [exterior_color, setExterior_color] = useState("");
  const [interior_color, setInterior_color] = useState("");
  const [key, setKey] = useState("");
  const [key_description, setKey_description] =  useState("");
  const [others, setOthers] =  useState("");
  const [vehicle_name, setVehicle_name] = useState("");
  const [ai_response, setAi_response] = useState("");
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState("");

  const [condition, setCondition] = useState("");
  const [stock_status, setStock_status] = useState("");
  const [colorStockStatus, setColorStockStatus] = useState("");
  const [business_type, setBusiness_type] = useState("");
  const [sub_status, setSub_status] = useState("");
  const [assignment, setAssignment] = useState("");
  const [stock, setStock] = useState("");
  const [stock_date, setStock_date] = useState(new Date());
  const [odometer, setOdometer] = useState("");
  const [notes, setNotes] = useState("");
  const [exteriorColor, setExteriorColor] = useState("");
  const [interiorColor, setInteriorColor] = useState("");
  const [lot_location, setLot_location] = useState("");
  const [disclosure, setDisclosure] = useState("");



  const handleInput = (e) =>{
    setInventory({...inventory, [e.target.name] : e.target.value})
  }

  const handleGlobalInventory = (event) => {  
    const checkedValue = event.target.checked;
    setGlobal_inventory(checkedValue);
    setInventory({...inventory, global_inventory: checkedValue ? "true" : "false"});
  
  }; 

  
//   const handleYearChange = (selectedOption) => {
//     setYear(selectedOption.label);
//   };

//   const handleMakeChange = (selectedOption) => {
//     setBrand(selectedOption.label);
//   };

//   const handleModelChange = (selectedOption) => {
//     setModel_name(selectedOption.label);
//   };

//   const handleVehicleTypeChange = (selectedOption) => {
//     setVehicle_type(selectedOption.label);
//   };

//   const handleBodyTypeChange = (selectedOption) => {
//     setBody_type(selectedOption.label);
//   };

//   const handleEngineTypeChange = (selectedOption) => {
//     setEngine_type(selectedOption.label);
//   };

//   const handleFuelTypeChange = (selectedOption) => {
//     setFuel_type(selectedOption.label);
//   };

//   const handleTransmissionChange = (selectedOption) => {
//     setTransmission(selectedOption.label);
//   };

//   const handleDriveTypeChange = (selectedOption) => {
//     setDrive_type(selectedOption.label);
//   };


//   const handleDoorsChange = (selectedOption) => {
//     setDoor(selectedOption.label);
//   };

//   const handleConditionChange  = (event) => {
//     setCondition(event.target.value);
//   };

//   const handleStockStatusChange  = (event) => {
//     setStock_status(event.target.value);
//     };

//     const handleRetailChange  = (event) => {
//     setBusiness_type(event.target.value);
//     };
         
  
    const handleCheckOptionsai = (event) => {
      var updatedList = [...checkedoptionsai];
      if (event.target.checked) {
        updatedList = [...checkedoptionsai, event.target.value];
      } else {
        updatedList.splice(checkedoptionsai.indexOf(event.target.value), 1);
      }
  
      const stringData = updatedList.reduce((result, item) => {
          return `${result}${item} `
        }, " ")
        
      setCheckedoptionsai(updatedList);
      setPrompt(stringData);
     
    };
  


    useEffect(() => {
        setIsLoading(true);
       // const inventory_id = props.match.params.id;
        axios.get(`${API_URL}/edit-inventory/`+id, ).then(res=>{
           if(res.data.status === 200){
              setInventory(res.data.inventorySection);   
              setGlobal_inventory(JSON.parse(res.data.inventorySection.global_inventory));     
           }
           else if(res.data.status === 404){
          Swal("Error",res.data.error,"error");
             //navigate.push('/view-inventory');
             //console.log(res.data.error)
           }
      
           setIsLoading(false);
        });
       }, []);





    async function decode(event){
  
        event.preventDefault();
        setLoading(true);
       if(vin.length === 17)
      {
      const response = await fetch(`https://auto.dev/api/vin/${vin}?apikey=ZrQEPSkKa2VlcnRoaXZhaGVlc2FuQGdtYWlsLmNvbQ==`);
    
       //console.log(`response status is ${response.status}`);
        const mediaType = response.headers.get('content-type');
       let data;
       if (mediaType.includes('json')) {
         data = await response.json();
       } else {
         data = await response.text();     
       }
      
      // console.log(data);
       if(data.make){
        setError("");
       setData(JSON.stringify(data, null, "\t"));
       setBrand(data.make.name);
   setModel_name(data.model.name);
   setYear(data.years[0].year);
   setEngine_type(data.engine.name);
   setDrive_type(data.drivenWheels);
   setTransmission(data.transmission.transmissionType);
   setDoor(data.numOfDoors);
   setTrim(data.years[0].styles[0].trim);
   setVehicle_type(data.categories.vehicleType);
   setBody_type(data.categories.primaryBodyType);
   setCylinders(data.engine.cylinder);
   setFuel_type(data.engine.fuelType);
   setVehicle_name(data.years[0].year+" " + data.make.name +" "+data.model.name);

   setChecklistoptionsai([data.years[0].year,data.make.name,data.model.name, data.categories.vehicleType,
   data.transmission.transmissionType,data.years[0].styles[0].trim,data.engine.fuelType]);

   setCheckedoptionsai([data.years[0].year.toString(),data.make.name,data.model.name, data.categories.vehicleType,
    data.transmission.transmissionType,data.years[0].styles[0].trim,data.engine.fuelType]);
   //console.log(checklistoptionsai);


   const stringData = checklistoptionsai.reduce((result, item) => {
    return `${result}${item} `
  }, " ")

  setPrompt([data.years[0].year,data.make.name,data.model.name, data.categories.vehicleType,
    data.transmission.transmissionType,data.years[0].styles[0].trim,data.engine.fuelType].reduce((result, item) => {
    return `${result}${item} `
  }, " "));
  
}
else{
 setError(data.message);
}
    
    }
    else {
       setError("Please enter 17 digit vin number");
       
       
    }
    setLoading(false);
    }
    
    
    
    
      const openai = new OpenAI({
        apiKey : "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", dangerouslyAllowBrowser: true
      });
      
      async function generateAI(e){
        e.preventDefault();
        setLoading(true);
        try {
      
          const chatCompletion = await openai.chat.completions.create({
            model: "gpt-3.5-turbo",
            messages: [{"role": "user", "content": prompt,}],
            max_tokens:50
          });
      // console.log(prompt)
      const ai_response = chatCompletion.choices[0].message.content;
      //console.log(setInventory({...inventory, ai_response:"response"}))
      setInventory({...inventory, ai_response:ai_response})
        //  setAi_response(inventory.ai_response);
         
        } catch (e) {  
            setInventory({...inventory, ai_response:"Please try again"})
        //   setAi_response("Please try again.");
        }
        setLoading(false);
      };




      async function update(event)
      {
       event.preventDefault();
      
      setIsLoading(true);
          
          const data = inventory;
           
           await axios.put(`${API_URL}/update-inventory/` +id ,data).then(res =>{
//         if(res.data.status === 200){
// Swal("Success",res.data.message,"success");
//         }
//        else if(res.data.status === 422){
        
//         setErrorList(res.data.validate_err);
//         Swal("Error",res.data.message,"error");
        
//        }
//        else if(res.data.status === 404){
//         Swal("Error",res.data.message,"error");
//        }

setErrorList([]);
const id = res.data.inventorySection.id;        
navigate('/edit-inventorySection2/'+id);

    // Swal.fire({
    //           icon:"success",
    //           text:res.data.message
    //         }).then(function(){
    //            setErrorList([]);
    //            const id = res.data.inventorySection.id;        
    //            navigate('/edit-inventorySection2/'+id);             
    //       });
          }).catch(function(error) {
            setIsLoading(false);
             // if(error.response.status===422){         
             setErrorList(error.response.data.validate_err);
             // }
         //    else{
              Swal.fire({
             
                text:error.response.data.message,
                icon:"error"
              })
         //    }
          })
       
          }
      



    return(
<div>

<SideNav/>
    <TopBar/>

    {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}

    <div class="d-flex">

<div class="right_panel position create_inventory h-100" id="right_panel">

{/* <!-- Main Title --> */}
    <div class="main_title"  data-aos-delay="" data-aos-duration="1200">
        <h1><i class="bi bi-plus-lg h1"></i> Edit Inventory</h1>
    </div>
  

    <div class="content_wrapper pt-0">

        {/* <!-- ----------------------------------- -->
        <!-- Vehicle Information and Disclosures -->
        <!-- ----------------------------------- --> */}
        <form>
        <div id="inventory_section1">
            <div class="col">
                <div class="vm_content_box_1 h-100">
                    <div class="title">
                        <h2><i class="bi bi-car-front-fill"></i> Vehicle Information</h2>
                    </div>
                    <div class="content pt-3">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="highlightbox_1 vm_bg_secondary" style={{marginBottom: "10px"}}>
                                    <div class="highlightbox_1_title bg-dark">Vin Number</div>
                                    <div class="highlightbox_1_wrapper px-2">
                                        <div class="col-md-12 form-group border-3 d-flex">
                                            <input type=" number" class="form-control col" name="vin"  id="vin" placeholder="Enter VIN Number"
                                            value={inventory.vin} required onChange={handleInput }/>
                                            
                                            {/* <button class="vm_bg_primary border-0 text-white px-3" onClick={decode}><i class="bi bi-upc-scan pe-1"></i>{loading ? "Decoding..." : "Decode"}</button> */}
                                           
                                        </div>
                                    </div>
                                </div>
                              {/* {vin.length==17?null:<span class="text-danger">{error} </span>}  */}
                              <div style={{marginBottom:"10px"}}> {error && <span class="text-danger">{error} </span>}</div> 
                            </div>
                           
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="vehicle_name">Title</label>
                                    <input type="text" class="form-control" name="vehicle_name"  placeholder="Enter Vehicle Name " value={inventory.vehicle_name}
                                     id="vehicle_name"   onChange={handleInput } />
                                       <span class="text-danger">{error_list.vehicle_name}</span>
                                </div>
                            </div>
                          
                        </div>
                        <div class="row">

                        <div class="col-md-4">
                                <div class="form-group">
                                    <label for="condition">Condition</label>
                                    <select class="form-select" name="condition" id="condition" value={inventory.condition} onChange={handleInput } > 
                                        <option value="">Select Condition</option>
                                        <option value="Used">Used</option>
                                        <option value="Brand New">Brand New</option>
                                    </select>
                                    <span class="text-danger">{error_list.condition}</span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="status">Status</label>
                                    <select class="form-select"  name="stock_status" id="status" value={inventory.stock_status} onChange={handleInput }>
                                        <option value="">Select Status</option>
                                        <option value="In stock" style={{color:"green"}}>In stock</option>
                                        <option value="Out of Stock" style={{color:"red"}}>Out of Stock</option>
                                    </select>
                                    <span class="text-danger">{error_list.stock_status}</span>
                                    
                                </div>
                            </div>
                           
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="statusColor">Retail/Wholesale</label>
                                    <select class="form-select" name="business_type" id="statusColor"  value={inventory.business_type} onChange={handleInput }>
                                        <option value="">Select</option>
                                        <option value="Retail">Retail</option>
                                        <option value="Wholesale">Wholesale</option>
                                    </select>
                                    <span class="text-danger">{error_list.business_type}</span>
                                
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="sub_status">Substatuses</label>
                                    <input type="text" class="form-control"  value={inventory.sub_status} name="sub_status" id="substatuses" placeholder="Enter Substatuses" 
                                    onChange={handleInput }/>
                                    <span class="text-danger">{error_list.sub_status}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="assignment">Assignment</label>
                                    <input type="text" class="form-control" name="assignment" id="assignment" placeholder="Enter Assignment" value={inventory.assignment}
                                    onChange={handleInput }/>
                                           <span class="text-danger">{error_list.assignment}</span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="highlightbox_1" style={{margin: "13px -18px 13px -18px"}}>
                                    <div class="highlightbox_1_title" style={{marginLeft:"12px"}}>Stock Details</div>
                                    <div class="highlightbox_1_wrapper d-md-flex d-block">
                                    <div class="col-md-4" style={{padding: "0px 12px"}} >
                                            <div class="form-group" style={{padding: "0px 0px 15px"}}  >
                                                <label for="stock">Stock</label>
                                                <input type="number" class="form-control" name="stock" id="stock" placeholder="Enter Stock" value={inventory.stock}
                                                 onChange={handleInput } min="0"/>
                                                  <span class="text-danger">{error_list.stock}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-4"style={{padding: "0px 12px"}} >
                                            <div class="form-group" style={{padding: "0px 0px 15px"}}  >
                                                <label for="stock_date">In Stock Date</label>
                                                <input type="date" class="form-control" name="stock_date" id="instockDate" value={inventory.stock_date}  
                                                onChange={handleInput }
                                                placeholder="Select In Stock Date" />
                                                  <span class="text-danger">{error_list.stock_date}</span>
                                               
                                            </div>
                                        </div>

                                        <div class="col-md-4"style={{padding: "0px 12px"}} >
                                            <div class="form-group" style={{padding: "0px 0px 15px"}}  >
                                            <div class="content pt-3">
                                            <div class="form-group pt-3 pb-2 d-md-flex" style={{marginLeft: "40px"}}>
                                            <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch" name="global_inventory" id="global_inventory" checked={global_inventory } 
                                              onChange={handleGlobalInventory}/>
                                        
                                              <label class="form-check-label" for="global_inventory"> Global Inventory</label>
                                              </div>
                                              </div>
                                              </div>
                                               
                                               
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="odometer">Odometer</label>
                                    <input type="text" class="form-control" name="odometer" id="odometer" value={inventory.odometer} placeholder="Enter Odometer"
                                      onChange={handleInput }/>
                                       <span class="text-danger">{error_list.odometer}</span>
                                </div>
                            </div>
                            <div class="col-md-12">
                            <div class="highlightbox_1"  style={{margin: "13px -18px 13px -18px"}}>
                                    <div class="highlightbox_1_title" style={{marginLeft:"12px"}}>Make & Model</div>
                                    <div class="highlightbox_1_wrapper d-md-flex d-block">
                                    <div class="col-md-4" style={{padding: "0px 12px"}}>
                                            <div class="form-group" style={{padding: "0px 0px 15px"}}>
                                                <label for="year">Year</label>
                                                {decodeFlag?
                                                <select class="form-select"  value={inventory.year} name="year" id="year" 
                                               onChange={handleInput } >
                                    
                                                    <option value="">Select Year</option>
                                                    {inventory.year?<option selected="selected">{inventory.year}</option>:null}
                                                   
                                                </select>
                                                  : <input type="number" class="form-control" value={inventory.year} name="year" id="year" 
                                                  placeholder="Enter Year" onChange={handleInput }/>
                                                  }
                                                <span class="text-danger">{error_list.year}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-4"  style={{padding: "0px 12px"}}>
                                            <div class="form-group" style={{padding: "0px 0px 15px"}}>
                                                <label for="make">Make</label>
                                                {decodeFlag? 
                                                <select class="form-select"  name="brand" id="brand" value={inventory.brand}
                                                onChange={handleInput} >
                                                    <option value="">Select Make</option>
                                                    {inventory.brand?<option selected="selected">{inventory.brand}</option>:null}
                                               
                                                </select>
                                                : <input type="text" class="form-control" value={inventory.brand} name="brand" id="brand" 
                                                placeholder="Enter Make"  onChange={handleInput }/>
                                                }
                                                <span class="text-danger">{error_list.brand}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-4" style={{padding: "0px 12px"}}>
                                            <div class="form-group"  style={{padding: "0px 0px 15px"}}>
                                                <label for="model_name">Model</label>
                                                {decodeFlag? 
                                                <select class="form-select" name="model_name" id="model_name" value={inventory.model_name} 
                                                 onChange={handleInput } >
                                                    <option value="">Select Model</option>
                                                    {inventory.model_name?<option selected="selected">{inventory.model_name}</option>:null}
                                                
                                                </select>
                                                : <input type="text" class="form-control" value={inventory.model_name} name="model_name" id="model_name" 
                                                placeholder="Enter Model" onChange={handleInput } />
                                                }
                                                <span class="text-danger">{error_list.model_name}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="trim">Trim</label>
                                    <input type="text" class="form-control" name="trim" id="trim" value={inventory.trim} placeholder="Enter Trim" 
                                       onChange={handleInput } />
                                         <span class="text-danger">{error_list.trim}</span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="vehicle_type">Vehicle Type</label>
                                    {decodeFlag? 
                                    <select class="form-select" name="vehicle_type" value={inventory.vehicle_type} id="vehicle_type" onChange={handleInput} >
                                        <option value="">Select Type</option>
                                        {inventory.vehicle_type?<option selected="selected">{inventory.vehicle_type}</option>:null}
                                      
                                    </select>
                                      : <input type="text" class="form-control" value={inventory.vehicle_type} name="vehicle_type" id="vehicle_type" 
                                      placeholder="Enter Vehicle type" onChange={handleInput}/>
                                      }
                                    <span class="text-danger">{error_list.vehicle_type}</span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="bodyType">Body Type</label>
                                    {decodeFlag? 
                                    <select class="form-select" name="body_type" id="body_type" value={inventory.body_type} onChange={handleInput}>
                                        <option value="">Select Body Type</option>
                                        {inventory.body_type?<option selected="selected">{inventory.body_type}</option>:null}
                                      
                                    </select>
                                     : <input type="text" class="form-control" value={inventory.body_type} name="body_type" id="body_type" 
                                     placeholder="Enter Body type" onChange={handleInput}/>
                                     }

                                    <span class="text-danger">{error_list.body_type}</span>
                                </div>
                            </div>
                            <div class="col-md-12">
                            <div class="highlightbox_1" style={{margin: "13px -18px 13px -18px"}}>
                                    <div class="highlightbox_1_title" style={{marginLeft:"12px"}}>Engine Details</div>
                                    <div class="highlightbox_1_wrapper d-md-flex d-block">
                                    <div class="col-md-4" style={{padding: "0px 12px"}}>
                                            <div class="form-group" style={{padding: "0px 0px 15px"}} >
                                                <label for="engineType">Engine</label>
                                                {decodeFlag? 
                                                <select class="form-select" name="engine_type" id="engine_type" value={inventory.engine_type} onChange={handleInput}>
                                                    <option value="">Select Engine Type</option>
                                                    {inventory.engine_type?<option selected="selected">{inventory.engine_type}</option>:null}
                                                    
                                                </select>
                                                 : <input type="text" class="form-control" value={inventory.engine_type} name="engine_type" id="engine_type" 
                                                 placeholder="Enter Engine type" onChange={handleInput}/>
                                                 }
                                                <span class="text-danger">{error_list.engine_type}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-4" style={{padding: "0px 12px"}} >
                                            <div class="form-group"style={{padding: "0px 0px 15px"}} >
                                                <label for="cylinders">Cylinders</label>
                                                <input type="number" class="form-control" value={inventory.cylinders} name="cylinders" id="cylinders" 
                                                placeholder="Enter Cylinders" onChange={handleInput }/>
                                             <span class="text-danger">{error_list.cylinders}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-4" style={{padding: "0px 12px"}} >
                                            <div class="form-group"style={{padding: "0px 0px 15px"}} >
                                                <label for="fueltype">Fuel Type</label>
                                                {decodeFlag? 
                                                <select class="form-select" name="fuel_type" id="fuel_type" value={inventory.fuel_type} onChange={handleInput}>
                                                    <option value="">Select Fuel Type</option>
                                                    {inventory.fuel_type?<option selected="selected">{inventory.fuel_type}</option>:null}
                                                    
                                                </select>
                                                : <input type="text" class="form-control" value={inventory.fuel_type} name="fuel_type" id="fuel_type" 
                                                placeholder="Enter Fuel type" onChange={handleInput}/>
                                                }
                                                <span class="text-danger">{error_list.fuel_type}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                            <div class="highlightbox_1" style={{margin: "0px -18px 0px -18px"}}>
                                    <div class="highlightbox_1_title" style={{marginLeft:"12px"}}>Transmission</div>
                                    <div class="highlightbox_1_wrapper d-md-flex d-block">
                                    <div class="col-md-6" style={{padding: "0px 12px"}}>
                                            <div class="form-group" style={{padding: "0px 0px 15px"}}>
                                                <label for="transmission">Transmission</label>
                                                {decodeFlag? 
                                                <select class="form-select" name="transmission" id="transmission" value={inventory.transmission} onChange={handleInput}>
                                                    <option value="">Select Transmission Type</option>
                                                    {inventory.transmission?<option selected="selected">{inventory.transmission}</option>:null}
                                                    
                                                </select>
                                                 : <input type="text" class="form-control" value={inventory.transmission} name="transmission" id="transmission" 
                                                 placeholder="Enter Transmission type" onChange={handleInput}/>
                                                }
                                                <span class="text-danger">{error_list.transmission}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-6" style={{padding: "0px 12px"}}>
                                            <div class="form-group" style={{padding: "0px 0px 15px"}}>
                                                <label for="divetype">Drive Type</label>
                                                {decodeFlag? 
                                                <select class="form-select" name="drive_type" id="drivetype" value={inventory.drive_type} onChange={handleInput}>
                                                    <option value="">Select Drive Type</option>
                                                    {inventory.drive_type?<option selected="selected">{inventory.drive_type}</option>:null}
                                                </select>
                                                 : <input type="text" class="form-control" value={inventory.drive_type} name="drive_type" id="drive_type" 
                                                 placeholder="Enter Drive type" onChange={handleInput}/>
                                                 }
                                                <span class="text-danger">{error_list.drive_type}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="doors">Doors</label>
                                    {decodeFlag? 
                                    <select class="form-select" name="doors" id="doors" value={inventory.door} onChange={handleInput}>
                                        <option value="">Select</option>
                                        {inventory.door?<option selected="selected">{inventory.door}</option>:null}
                                     
                                    </select>
                                    : <input type="number" class="form-control" value={inventory.door} name="door" id="door" 
                                    placeholder="Enter no of doors" onChange={handleInput}/>
                                    }
                                    <span class="text-danger">{error_list.door}</span>
                                </div>
                            </div>
                            <div class="col-md-3 col-6">
                                <div class="form-group">
                                    <label for="exterior_color">Exterior Color</label>
                                    {/* <input style={{height: "2.07rem",padding: "8px"}} type="color" class="form-control" value={inventory.exterior_color}  name="exterior_color" id="exterior_color" placeholder="Select Exterior Color"
                                      onChange={handleInput }/> */}
                                   
{decodeFlag? 
                       <select class="form-select" name="exterior_color" id="exterior_color" value={inventory.exterior_color} onChange={handleInput}>
                       <option value="">Select</option>
                       {exteriorColor.map((option, index) => (
                       <option key={index} value={option.name}>{option.name}</option>
                        ))}
                        </select>
                                                 :  <input type="text" class="form-control" name="exterior_color" value={inventory.exterior_color} id="exterior_color" placeholder="Enter Exterior Color" 
                                                 onChange={handleInput}/>
                                                 }  

                                   
                                       <span class="text-danger">{error_list.exterior_color}</span>
                                </div>
                            </div>
                            <div class="col-md-3 col-6">
                                <div class="form-group">
                                    <label for="interior_color">Interior Color</label>
                                    {/* <input style={{height: "2.07rem",padding: "8px"}} type="color" class="form-control" value={inventory.interior_color} name="interior_color" id="interior_color" placeholder="Select Interior Color"
                                     onChange={handleInput }/> */}
                                     {decodeFlag? 
                       <select class="form-select" name="interior_color" id="interior_color" value={inventory.interior_color} onChange={handleInput}>
                       <option value="">Select</option>
                       {interiorColor.map((option, index) => (
                       <option key={index} value={option.name}>{option.name}</option>
                        ))}
                        </select>
                                                 :  <input type="text" class="form-control" name="interior_color" value={inventory.interior_color} id="interior_color" placeholder="Enter Interior Color" 
                                                 onChange={handleInput}/>
                                                 }  

                                     <span class="text-danger">{error_list.interior_color}</span>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="key">Key</label>
                                    <input type="number" class="form-control" value={inventory.key} name="key" id="key" placeholder="Enter Key" 
                                      onChange={handleInput } min="0"/>
                                       <span class="text-danger">{error_list.key}</span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="lotlocation">Lot Location</label>
                                    <textarea class="form-control h-100" name="lot_location" value={inventory.lot_location} id="lot_location" cols="" rows="3" placeholder="Ex: at auction"
                                      onChange={handleInput }></textarea>
                                       <span class="text-danger">{error_list.lot_location}</span>
                                </div>
                            </div>
                            <div class="col-md-12">
                            <div class="highlightbox_1" style={{margin: "13px -15px 13px -15px"}}>
                                    <div class="highlightbox_1_title" style={{marginLeft:"10px"}} >Key Description</div>
                                    <div class="form-group px-2">
                                        <textarea style={{marginTop:"10px"}} class="form-control h-100 w-100" name="key_description"  id="key_description" value={inventory.key_description} cols="100%" rows="3" placeholder="Ex: at auction"
                                        onChange={handleInput }></textarea>
                                          <span class="text-danger">{error_list.key_description}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="other">Other</label>
                                    <input type="text" class="form-control" name="others" value={inventory.others} id="other" placeholder="Ex: Paid" 
                                    onChange={handleInput }/>
                                     <span class="text-danger">{error_list.others}</span>
                                </div>
                            </div>
                            <div class="col-md-12 pt-2">
                                <div class="form-group pb-1">
                                    <div class="d-flex justify-content-between">
                                        <label for="lotlocation">Generate AI</label>
                                        <button  class="btn_secondary text-white mb-1 py-0" onClick={generateAI}>{loading ? "Generating..." : "Generate AI"}</button>
                                        </div>
                                     
                                      {checklistoptionsai.map((item, index) => (
                                        
                                    <div  key={index} style={{float:"left",margin: "0px 0px 0px 20px"}}>
                                        
                                    <input class="checkbox" value={item} type="checkbox" defaultChecked={checkedoptionsai} name="leatherInterior" id="leatherInterior" onChange={handleCheckOptionsai} />
                                    <label for="leatherInterior" className={isCheckedoptionsai(item)}>{item}</label>
                                
                                    </div>
                                  
                                   
                                 
                                    ))} 


                            

                                   
                                    <textarea class="form-control h-100" name="generate_ai" id="generate_ai" value={prompt}  cols="" rows="3" placeholder="Ex: Enter the text to generate AI..."
                                    onChange={(e) => setPrompt(e.target.value)}>
                                    </textarea>
                                    {/* <div>{aiResponse}</div> */}
                                    {/* {aiResponse?{aiResponse}:null} */}
                                    
                               {/* {checkedoptionsai.join(' ')} */}
                                </div>
                            </div>


                            <div class="col-md-12 pt-3">
                                <div class="form-group">
                                    <label for="aiResponse">AI generated Description</label>
                                    <textarea class="form-control h-100" name="ai_response" value={inventory.ai_response} id="ai_response" cols="" rows="5" placeholder=""></textarea>
                                </div>
                                <span class="text-danger">{error_list.ai_response}</span>
                            </div>



                            <div class="col-md-12 pt-2">
                                <div class="form-group">
                                    <label for="notes">Notes</label>
                                    <textarea class="form-control h-100" name="notes" id="notes" cols=""  value={inventory.notes} rows="3" placeholder="Enter Notes" 
                                      onChange={handleInput }></textarea>
                                       <span class="text-danger">{error_list.notes}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col ms-lg-4 pt-4 pt-lg-0">
                <div class="vm_content_box_1 h-100">
                    <div class="title">
                        <h2><i class="bi bi-file-earmark-ruled"></i> Disclosures</h2>
                    </div>
                    <div class="content pt-3">
                        <p>This disclosure builder tool enables you to quickly add common disclosures to your vehicle. You can search for a disclosure using the search box. Simply drag-n-drop it into the notes box and the disclosure will automatically be applied. You can create additional custom disclosures from the setting</p>
                        <div class="form-group pt-3 pb-4 d-md-flex">
                            <div class="form-check form-switch d-flex align-items-center">
                                <input class="form-check-input" type="radio" role="switch" name="discl_status" id="discl_na" value="N/A" checked/>
                                <label class="form-check-label pb-0 ps-2" for="discl_na">N|A</label>
                            </div>
                            <div class="form-check form-switch d-flex align-items-center ms-md-3 pt-3 pt-md-0">
                                <input class="form-check-input" type="radio" role="switch" name="discl_status" id="discl_none" value="None"/>
                                <label class="form-check-label pb-0 ps-2" for="discl_none">None</label>
                            </div>
                            <div class="form-check form-switch d-flex align-items-center ms-md-3 pt-3 pt-md-0">
                                <input class="form-check-input" type="radio" role="switch" name="discl_status" id="discl_rebuilt" value="Rebuilt"/>
                                <label class="form-check-label pb-0 ps-2" for="discl_rebuilt">Rebuilt</label>
                            </div>
                            <div class="form-check form-switch d-flex align-items-center ms-md-3 pt-3 pt-md-0">
                                <input class="form-check-input" type="radio" role="switch" name="discl_status" id="discl_salvage" value="Salvage"/>
                                <label class="form-check-label pb-0 ps-2" for="discl_salvage">Salvage</label>
                            </div>
                            <div class="form-check form-switch d-flex align-items-center ms-md-3 pt-3 pt-md-0">
                                <input class="form-check-input" type="radio" role="switch" name="discl_status" id="discl_irreparable" value="Irreparable"/>
                                <label class="form-check-label pb-0 ps-2" for="discl_irreparable">Irreparable</label>
                            </div>
                        </div>
                        <div class="form-group d-flex">
                            <button class="bg-dark text-white border-dark px-2"><i class="bi bi-search"></i></button>
                            <input class="form-control" name="brandType" id="brandType" placeholder="Brand Type" type="text"/>
                        </div>
                        <div class="highlightbox_1 py-2 mb-2">
                            <div class="col-md-12">
                                <div class="d-flex justify-content-between align-items-center">
                                    <p for="stock">Vehicle is previous daily rental/fleet</p>
                                    <button class="btn_dark">Add</button>
                                </div>
                            </div>
                        </div>
                        <div class="highlightbox_1 py-2 mb-2">
                            <div class="col-md-12">
                                <div class="d-flex justify-content-between align-items-center">
                                    <p for="stock">Vehicle Previous out of province vehicle. Registered in</p>
                                    <button class="btn_dark">Add</button>
                                </div>
                            </div>
                        </div>
                        <div class="highlightbox_1 py-2 mb-2">
                            <div class="col-md-12">
                                <div class="d-flex justify-content-between align-items-center">
                                    <p for="stock">Customer declined ot purchase GAP insurance at this time</p>
                                    <button class="btn_dark">Add</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 pt-3">
                            <div class="form-group pb-1">
                                <div class="d-flex justify-content-between">
                                    <label for="lotlocation">Generate AI</label>
                                    <button class="btn_secondary text-white mb-1 py-0">Generate AI</button>
                                </div>
                                <textarea class="form-control h-100" name="generate_ai" id="generate_ai" cols="" rows="5" placeholder="Ex: Birthday Greeting..."></textarea>
                            </div>
                        </div>
                        <div class="col-md-12 pt-3 pb-3">
                            <div class="form-group pb-0">
                                <textarea class="form-control h-100" name="disclosure" value={inventory.disclosure} id="desc" cols="" rows="4" placeholder="Enter Description"
                                  onChange={handleInput }></textarea>
                                   <span class="text-danger">{error_list.disclosure}</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* <!-- Next Button --> */}

            {/* <div class="d-flex justify-content-md-start position-fixed bottom-0 vm_zindex_99 w-100 fixed_inv_btn">
                <div class="vm_bg_secondary px-4 start-0 end-0 pt-2 pb-2 vm_font_semi_bold text-white text-uppercase vm_cursor_pointer" 
                  onClick={save}>
                    Save <i class="bi bi-arrow-right-short"></i>
                </div>
            </div> */}

            

            <div class="d-flex justify-content-md-start position-fixed bottom-0 vm_zindex_99 w-100 fixed_inv_btn">
                <div class="vm_bg_secondary px-4 start-0 end-0 pt-2 pb-2 vm_font_semi_bold text-white text-uppercase vm_cursor_pointer" 
                onClick={update}>
                    Next <i class="bi bi-arrow-right-short"></i>
                </div>
            </div>


{/* {id?<div class="d-flex justify-content-md-start position-fixed bottom-0 vm_zindex_99 w-100 fixed_inv_btn">
                <div class="vm_bg_secondary px-4 start-0 end-0 pt-2 pb-2 vm_font_semi_bold text-white text-uppercase vm_cursor_pointer" 
                  onClick={update}>
                    update <i class="bi bi-arrow-right-short"></i>
                </div>
            </div>:null} */}
              



        </div>
        </form>






    </div>
</div>
</div>
    
</div>
    );

}
export default EditInventory;