import { React, useState,useContext, useEffect } from "react";
import Swal from 'sweetalert2';
import axios from 'axios';
import {Link, useParams, useNavigate} from "react-router-dom";
import UserContext from './UserContext';

function TopBarInventory(){

    const API_URL = process.env.REACT_APP_API_URL;
    const [login, setLogin] = useState([]);
    //const { username } = useParams();
    const [message, setMessage] = useState('');
    //const [username, setUsername] = useState('');
    const userContext = useContext(UserContext);  
  
    useEffect(() => {       
        //getLoginDetails();
       // console.log(username)
       
    }, []);
    

    return(
      
            
            <div class="border-bottom pe-3 d-flex align-items-center justify-content-between bg-white topbar w-100 position-fixed" id="topbar">
    <div class="d-flex align-items-center">
        <i class="bi bi-arrow-left-short vm_font_bold vm_cursor_pointer fs-3" id="hideMenu" onclick="hideMenu()"></i>
        <i class="bi bi-list vm_font_bold vm_cursor_pointer fs-3" id="showMenu" onclick="showMenu()" style={{display: "none"}}></i>
        <div class="d-flex align-items-center ms-3">
        
        </div>
    </div>
    <div class="d-flex align-items-center">
        <i class="bi bi-bell fs-5"></i>
        <div class="user d-flex align-items-center ps-md-3">
            <p class="d-md-block d-none">{userContext.username}</p>
            <div class="dropdown">
                <div class="user_photo ms-2 vm_cursor_pointer vm_bg_center vm_bg_cover vm_bg_norepeat" style={{background: "url('img/user_login_photo.webp')"}} data-bs-toggle="dropdown" aria-expanded="false"></div>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#"><i class="bi bi-person"></i> Profile</a></li>
                    <li><a class="dropdown-item" href="#"><i class="bi bi-gear"></i> Setting</a></li>
                    <li><a class="dropdown-item" href="/login"><i class="bi bi-box-arrow-right"></i> Logout</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>

       
    )
}
 export default TopBarInventory;